import { defineStore } from 'pinia'
import { ref, computed } from 'vue'

import { useStore } from '@/store/useStore'
// imported directly from store because this composable is used
// before the app is initialized and useStore is not available
import { getInitialState } from '@/store'
import type { Ability, Setup2FAResponsePayload } from '@/store/types'
import type { LoginResponsePayload } from '@/store/types'
import type { TeamOwnerInvitationPayload } from '@/store/types/TeamOwnerInvitationPayload'
import * as api from '@/backend/api'
import {
  login as loginRequest,
  setup2fa as setup2faRequest,
  confirm2fa as confirm2faRequest,
  login2fa as login2faRequest,
  loginSSO as loginSSORequest,
} from '@/backend/darwin'
import { loadFeatures } from '@/backend/darwin/loadFeatures'
import { loadTeamFeatures } from '@/backend/darwin/loadTeamFeatures'
import type { ParsedError } from '@/backend/error'
import { constructError } from '@/backend/error'
import { errorMessages, isErrorResponse, parseError } from '@/backend/error'
import session from '@/backend/session'
import { Socket } from '@/backend/socket'
import { getToken, updateToken } from '@/backend/token'

import { useFeatureFlagsStore } from '@/pinia/useFeatureFlagsStore'
import { useWorkviewV2TrackerStore } from '@/modules/Workview/useWorkviewV2TrackerStore'
import { useWorkviewV3TrackerStore } from '@/modules/Workview/useWorkviewV3TrackerStore'
import { toggleReplay } from '@/services/sentry'
import { useTeamStore } from '@/pinia/useTeamStore'
import { useBillingStore } from '@/modules/Billing/useBillingStore'
import { useCreditUsageStore } from '@/modules/Billing/useCreditUsageStore'
import { useApiKeyStore } from '@/modules/ApiKeys/useApiKeyStore'
import { useAnnotationTypeLoader } from '@/modules/Classes/useAnnotationTypeLoader'
import { useClasses } from '@/modules/Classes/useClasses'

import type { IsAuthorized } from './IsAuthorized'
import { checkAuthorized } from './IsAuthorized'
import { useUserStore } from './useUserStore'
import { defineComposable } from '@/core/utils/defineComposable'
import type { AxiosResponse } from 'axios'

export const useAuthStore = defineComposable((vuexStore?: ReturnType<typeof useStore>) =>
  defineStore('auth', () => {
    const store = vuexStore || useStore()
    const workviewV2Tracker = useWorkviewV2TrackerStore()
    const workviewV3Tracker = useWorkviewV3TrackerStore()
    const billingStore = useBillingStore()
    const creditUsageStore = useCreditUsageStore()

    const abilities = ref<Ability[]>([])
    const authenticated = ref<boolean>(false)
    const invitation = ref<{
      email: string
      userFirstName: string | null
      teamName: string | null
    } | null>(null)
    const tfaCredentials = ref<{
      email: string
      password: string
    } | null>(null)

    // actions
    const resetState = (): void => {
      abilities.value = []
      authenticated.value = false
      invitation.value = null
      tfaCredentials.value = null
    }

    const SET_2FA_CREDENTIALS = (params: (typeof tfaCredentials)['value']): void => {
      tfaCredentials.value = params
    }

    const SET_AUTHENTICATED = (newValue: boolean): void => {
      authenticated.value = newValue
    }

    const SET_ABILITIES = (x: (typeof abilities)['value']): void => {
      abilities.value = x
    }

    const SET_INVITATION = (x: (typeof invitation)['value']): void => {
      invitation.value = x
    }

    /**
     * Produces function that can be used to check whether a user is authorized
     * to perform an ability
     *
     * Determines if currently signed in user can perform a specific action,
     * either generally, or on a specific resource.
     *
     * NOTE: Backdoor
     *
     * Currently, the frontend sometimes doesn't really know if the backend allows a
     * user to do something.
     *
     * For example, the user might be allowed to create annotator invites only, but
     * the backend might return no such ability.
     *
     * When this is the case, the final argument of the function returned by this
     * getter is a list of allowed roles. If the user's current role matches one in
     * the list, the frontend will act as if the user is allowed performing the action.
     *
     * This is a temporary measure and should be eliminated fully, eventually, as
     * it makes for bad UX and the backend still might return a 401/403 in some cases.
     */
    const isAuthorized = computed(
      (): IsAuthorized =>
        (
          ability,
          // the actual full record needs to be passed in
          // because the `restrict_exports` field specifically affects this ability
          passedOptions,
          passedAllowedRoles,
        ): boolean => {
          // TODO DAR-1584: needs to be called inline to deal with team store relying on vuex store
          // can be moved out once we deal with that
          const options = passedOptions || { subject: 'team', resource: useTeamStore().currentTeam }
          const allowedRoles = passedAllowedRoles || []
          // We first check store abilities only. If user is authorized this way,
          // that is the correct auth approach and we return te result.
          const byAbility = checkAuthorized(abilities.value, ability, options)

          if (byAbility) {
            return true
          }

          // TODO DAR-1584: needs to be called inline to deal with team store relying on vuex store
          // can be moved out once we deal with that
          const userStore = useUserStore()
          const user = userStore.currentUser
          // isAuthorized is called from router,
          // before any component is mounted, so useStore cannot work directly
          // due to this, this pinia store is wrapped into a function
          // that allows a global vuex store to be passed in as argument
          // this is why, in this function, we have to still directly use vuex store state
          // once we migrate useTeamStore fully to pinia, this will no longer be needed

          const team = useTeamStore().currentTeam

          if (!user || !team) {
            return false
          }

          const membership = store.state.team.memberships.find(
            (m) => m.user_id === user.id && m.team_id === team.id,
          )
          return !!membership && allowedRoles.includes(membership.role)
        },
    )

    const features = useFeatureFlagsStore()

    const reloadFeatures = async (): Promise<void> => {
      const teamStore = useTeamStore()
      // Some features are based on team, so they need to be re-fetched upon login
      const result = teamStore.currentTeam
        ? await loadTeamFeatures(teamStore.currentTeam.id)
        : await loadFeatures()
      if ('data' in result) {
        features.setFeatures(result.data)
        toggleReplay(features.featureFlags.SENTRY_REPLAY)
      }
    }

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const login = async (params: { email: string; password: string; rememberMe: boolean }) => {
      const { email, password, rememberMe } = params

      const response = await loginRequest({ email, password })
      if ('error' in response) {
        return response
      }

      const { data } = response
      if ('required_2fa' in data) {
        return response
      }

      SET_AUTHENTICATED(true)
      SET_ABILITIES(data.selected_team_abilities)

      // TODO DAR-1584: needs to be called inline to deal with team store relying on vuex store
      // can be moved out once we deal with that
      useUserStore().currentUser = data
      store.commit('team/SET_CURRENT_TEAM', data.selected_team)
      store.commit('team/SET_TEAMS', data.teams)

      session.authenticate({
        isPermanent: rememberMe,
        refreshToken: data.refresh_token,
        token: data.token,
        tokenExpiration: data.token_expiration,
      })

      await reloadFeatures()

      // need to refetch types as upon logout store is reset
      await useAnnotationTypeLoader().load()

      return response
    }

    const loginWithToken = async (): Promise<
      ParsedError | { data: LoginResponsePayload | null }
    > => {
      let response

      try {
        response = await api.get<LoginResponsePayload>('users/token_info')
      } catch (error) {
        if (!isErrorResponse(error)) {
          throw error
        }
        return parseError(error, errorMessages.AUTH_LOGIN_WITH_TOKEN)
      }

      const { data } = response

      SET_AUTHENTICATED(true)
      SET_ABILITIES(data.selected_team_abilities)
      // TODO DAR-1584: needs to be called inline to deal with team store relying on vuex store
      // can be moved out once we deal with that
      useUserStore().currentUser = data
      store.commit('team/SET_CURRENT_TEAM', data.selected_team)
      store.commit('team/SET_TEAMS', data.teams)

      updateToken({
        token: data.token,
        tokenExpiration: data.token_expiration,
      })

      await reloadFeatures()

      return { data }
    }

    /**
     * Request 2fa secret key to the backend
     */
    const setup2fa = async (): Promise<ParsedError | { data: Setup2FAResponsePayload }> => {
      if (tfaCredentials.value) {
        const { email, password } = tfaCredentials.value
        const response = await setup2faRequest({ email, password })
        if ('data' in response) {
          return { data: response.data }
        }
      }

      const token = getToken()
      if (token) {
        const response = await setup2faRequest({ access_token: token })
        if ('data' in response) {
          return { data: response.data }
        }
      }

      return constructError('AUTH_SETUP_2FA')
    }

    /**
     * Request 2fa secret key to the backend
     */
    const confirm2fa = async (
      params:
        | {
            email: string
            password: string
            token: string
          }
        | { token: string },
    ): Promise<ParsedError | { data: unknown }> => {
      if ('email' in params) {
        const response = await confirm2faRequest(params)
        return response
      }

      const accessToken = getToken()
      if (accessToken) {
        const response = await confirm2faRequest({
          access_token: accessToken,
          token: params.token,
        })
        return response
      }

      return constructError('AUTH_CONFIRM_2FA')
    }

    /**
     * Confirms a user's invitation to join an existing team.
     * This will crate a new account alongside a membership to that team,
     * and return the full set of credentials one would get when logging
     * in with an existing account.
     */
    const confirmInvitation = async (params: {
      // only the token is actually required
      token: string
      // these are required when signing up while confirming
      email?: string
      password?: string
      firstName?: string
      lastName?: string
      // these are required when signing up while confirming, via 2fa
      twoFactorAuthEnabled?: boolean
      agreedToTos?: boolean
      hash?: string
    }): Promise<ParsedError | { data: LoginResponsePayload }> => {
      let response
      try {
        response = await api.put<LoginResponsePayload>('invitations/confirm', {
          email: params.email,
          first_name: params.firstName,
          last_name: params.lastName,
          password: params.password,
          hash: params.hash,
          token: params.token,
          two_factor_auth_enabled: params.twoFactorAuthEnabled,
          agreed_to_tos: params.agreedToTos,
        })
      } catch (error) {
        if (!isErrorResponse(error)) {
          throw error
        }
        return parseError(error, errorMessages.AUTH_CONFIRM_INVITATION)
      }

      const { data } = response
      SET_AUTHENTICATED(true)
      SET_ABILITIES(data.selected_team_abilities)

      session.authenticate({
        refreshToken: data.refresh_token,
        token: data.token,
        tokenExpiration: data.token_expiration,
      })

      // TODO DAR-1584: needs to be called inline to deal with team store relying on vuex store
      // can be moved out once we deal with that
      useUserStore().currentUser = data
      store.commit('team/SET_CURRENT_TEAM', data.selected_team)
      store.commit('team/SET_TEAMS', data.teams)

      await reloadFeatures()
      return { data }
    }

    const forgotPassword = async (params: {
      email: string
    }): Promise<AxiosResponse | ParsedError> => {
      let response

      try {
        response = await api.post<{ email: string }>('users/request_password_reset', {
          email: params.email,
        })
      } catch (error) {
        if (!isErrorResponse(error)) {
          throw error
        }
        return parseError(error, errorMessages.AUTH_FORGOT_PASSWORD)
      }

      return response
    }

    const login2fa = async (params: {
      email: string
      password: string
      token: string
    }): Promise<ParsedError | { data: LoginResponsePayload }> => {
      const response = await login2faRequest(params)
      if ('error' in response) {
        return response
      }

      const { data } = response
      SET_AUTHENTICATED(true)
      SET_ABILITIES(data.selected_team_abilities)
      // TODO DAR-1584: needs to be called inline to deal with team store relying on vuex store
      // can be moved out once we deal with that
      useUserStore().currentUser = data
      store.commit('team/SET_CURRENT_TEAM', data.selected_team)
      store.commit('team/SET_TEAMS', data.teams)

      session.authenticate({
        isPermanent: true,
        refreshToken: data.refresh_token,
        token: data.token,
        tokenExpiration: data.token_expiration,
      })

      // Some features are based on team, so they need to be re-fetched upon login
      await reloadFeatures()

      return response
    }

    const loginWithSSO = (params: { teamName: string }): void => {
      loginSSORequest(params)
    }

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const logout = async () => {
      let response
      try {
        response = await api.logout()
      } catch (error) {
        if (!isErrorResponse(error)) {
          throw error
        }
        return parseError(error, errorMessages.AUTH_LOGOUT)
      } finally {
        session.logout()
      }

      return response
    }

    const logoutStore = async (): Promise<void> => {
      SET_AUTHENTICATED(false)
      SET_ABILITIES([])
      await Socket.disconnect()
      store.replaceState(getInitialState())
    }

    const passwordReset = async (params: {
      password: string
      confirm: string
      token: string
    }): Promise<AxiosResponse | ParsedError> => {
      let response

      try {
        response = await api.put<LoginResponsePayload>('users/reset_password', {
          password: params.password,
          password_confirmation: params.confirm,
          token: params.token,
        })
      } catch (error) {
        if (!isErrorResponse(error)) {
          throw error
        }
        return parseError(error, errorMessages.AUTH_RESET_PASSWORD)
      }

      const { data } = response
      SET_AUTHENTICATED(true)
      SET_ABILITIES(data.selected_team_abilities)
      // TODO DAR-1584: needs to be called inline to deal with team store relying on vuex store
      // can be moved out once we deal with that
      useUserStore().currentUser = data
      store.commit('team/SET_CURRENT_TEAM', data.selected_team)
      store.commit('team/SET_TEAMS', data.teams)

      session.authenticate({
        refreshToken: data.refresh_token,
        token: data.token,
        tokenExpiration: data.token_expiration,
      })

      await reloadFeatures()

      return response
    }

    const register = async (params: {
      email: string
      firstName: string
      lastName: string
      password: string
      twoFactorAuthEnabled: boolean
      agreedToTos: boolean
      token: string
      hash?: string
    }): Promise<AxiosResponse | ParsedError> => {
      let response

      try {
        response = await api.post<LoginResponsePayload>('users/register', {
          email: params.email,
          first_name: params.firstName,
          last_name: params.lastName,
          password: params.password,
          hash: params.hash,
          two_factor_auth_enabled: params.twoFactorAuthEnabled,
          agreed_to_tos: params.agreedToTos,
          token: params.token,
        })
      } catch (error) {
        if (!isErrorResponse(error)) {
          throw error
        }
        return parseError(error, errorMessages.AUTH_REGISTER)
      }

      const { data } = response
      SET_AUTHENTICATED(true)
      SET_ABILITIES(data.selected_team_abilities)
      // TODO DAR-1584: needs to be called inline to deal with team store relying on vuex store
      // can be moved out once we deal with that
      useUserStore().currentUser = data
      store.commit('team/SET_CURRENT_TEAM', data.selected_team)
      store.commit('team/SET_TEAMS', data.teams)

      session.authenticate({
        refreshToken: data.refresh_token,
        token: data.token,
        tokenExpiration: data.token_expiration,
      })

      await reloadFeatures()

      return response
    }

    const selectTeam = async (params: {
      team_id: number
    }): Promise<AxiosResponse | ParsedError> => {
      let response

      try {
        response = await api.selectTeam(params)
      } catch (error) {
        if (!isErrorResponse(error)) {
          throw error
        }
        return parseError(error, errorMessages.AUTH_SELECT_TEAM)
      }

      // We reset picked module's state to initial state while changing the team
      // The user, auth, toast and feedback stores are not team-dependent,
      // so we do not need to reset those
      store.commit('dataset/RESET_ALL', null)
      store.commit('datasetUpload/RESET_ALL', null)
      store.commit('workview/RESET_ALL', null)
      workviewV2Tracker.resetState()
      workviewV3Tracker.resetState()
      billingStore.resetState()
      creditUsageStore.resetState()
      useApiKeyStore().resetState()
      useClasses().resetState()

      const { data } = response
      SET_ABILITIES(data.selected_team_abilities)
      store.commit('team/SET_CURRENT_TEAM', data.selected_team)
      store.commit('team/SET_TEAMS', data.teams)
      features.setFeatures([])

      session.authenticate({
        refreshToken: data.refresh_token,
        token: data.token,
        tokenExpiration: data.token_expiration,
      })

      // Some features are based on team, so they need to be re-fetched upon login
      await reloadFeatures()

      return response
    }

    /**
     * Verifies that a team owner invitation token is
     * - valid
     * - not expired
     */
    const verifyTeamOwnerInvitation = async (
      token: string,
    ): Promise<ParsedError | { data: TeamOwnerInvitationPayload }> => {
      try {
        const response = await api.post<TeamOwnerInvitationPayload>('users/invitations/validate', {
          token,
        })
        return { data: response.data }
      } catch (error) {
        if (!isErrorResponse(error)) {
          throw error
        }
        return parseError(error, errorMessages.AUTH_VERIFY_TEAM_OWNER_INVITATION)
      }
    }

    return {
      // state
      abilities,
      authenticated,
      invitation,
      tfaCredentials,

      // actions
      resetState,
      login,
      loginWithToken,
      setup2fa,
      confirm2fa,
      confirmInvitation,
      forgotPassword,
      login2fa,
      loginWithSSO,
      logout,
      logoutStore,
      passwordReset,
      register,
      selectTeam,
      verifyTeamOwnerInvitation,

      // getters
      isAuthorized,

      // mutations (using uppercase to differentiate from actions)
      SET_2FA_CREDENTIALS,
      SET_AUTHENTICATED,
      SET_ABILITIES,
      SET_INVITATION,
    }
  })(),
)
