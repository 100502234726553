/**
 * As we move editor towards being a separate thing, we will more and more
 * encounter situations where we have to convert store models into editor models.
 *
 * This file should contain such functions.
 *
 * As we move forward and it gets bigger, it may become a folder
 */
import type { AnnotationClass } from '@/modules/Editor/AnnotationClass'
import { classColor, classColorRGBAString } from '@/modules/Workview/utils'
import type { AnnotationClassPayload } from '@/store/types/AnnotationClassPayload'
import { AnnotationSubTypeGranularity } from '@/core/annotations'

/**
 * Converts store annotation class payload into a simplified version used by the editor.
 *
 * Long term, this ought to be fully eliminated and merged into the annotation itself.
 */
export const storeToEditorAnnotationClass = (payload: AnnotationClassPayload): AnnotationClass => ({
  id: payload.id,
  name: payload.name,
  eyeMetaData: payload.metadata.eye,
  skeletonMetaData: payload.metadata.skeleton,
  blackout: payload.metadata.blackout,
  annotation_types: payload.annotation_types,
  color: classColor(payload),
  colorRGBAstring: classColorRGBAString(payload),
  granularity: {
    instance_id:
      payload.metadata.instance_id?.granularity || AnnotationSubTypeGranularity.ANNOTATION,
  },
})
