import { v4 as uuidv4 } from 'uuid'

import type { VideoAnnotationData } from '@/modules/Editor/AnnotationData'
import { createAndSelectAnnotation } from '@/modules/Editor/actions/utils/createAndSelectAnnotation'
import type { Bounds } from '@/modules/Editor/models/raster/Raster'
import type { VoxelRaster } from '@/modules/Editor/models/raster/VoxelRaster'
import type { Plane } from '@/modules/Editor/utils/raster/Plane'
import type { View } from '@/modules/Editor/views/view'

import { getBoundsOfSegmentOnReformat } from './getBoundsOfSegmentOnReformat'

/**
 * Creates a new mask annotation and associates it with the raster.
 *
 * @param view The view on which the raster lives.
 * @param raster The raster object
 * @param boundingBox The bounding box to set on the annotation data.
 * @param labelIndex The label index of the annotation on the raster
 *
 * @returns A promise which resolves to the new annotation
 */
export async function createMaskAnnotationOnReformat(
  primaryView: View,
  voxelRaster: VoxelRaster,
  bounds: Bounds,
  labelIndex: number,
  classId: number,
  reformatFrameIndex: number,
  plane: Plane.X | Plane.Y,
  segmentOffset: number = 0,
): Promise<void> {
  const annotationId = uuidv4()

  voxelRaster.setAnnotationMapping(labelIndex, annotationId, classId)

  const firstFrame = bounds.topLeft.y
  // Bounds for a pixel filled at index [0, 0] will be:
  // { topLeft: { x: 0, y: 0 }, bottomRight: { x: 1, y: 1 } }
  // So we need to subtract 1 from the bottomRight.y to avoid creating empty frames.
  const lastFrame = Math.min(bounds.bottomRight.y - 1, voxelRaster.depth - 1)
  const xRangeOnReformat: [number, number] = [bounds.topLeft.x, bounds.bottomRight.x]

  const updatedFramesIndices: number[] = []

  const frames: VideoAnnotationData['frames'] = {}

  for (let frameIndex = firstFrame; frameIndex <= lastFrame; frameIndex++) {
    const boundsForFrame = getBoundsOfSegmentOnReformat(
      reformatFrameIndex,
      plane,
      xRangeOnReformat,
      voxelRaster,
      segmentOffset,
    )

    // If the voxel raster doesn't have a keyframe there, create one.
    if (!voxelRaster.frameBuffers[frameIndex]) {
      voxelRaster.createNewKeyframe(frameIndex)
    }

    voxelRaster.setVideoBoundsForLabelIndexForFrame(labelIndex, frameIndex, boundsForFrame)
    voxelRaster.setLabelOnKeyframe(labelIndex, frameIndex)
    updatedFramesIndices.push(frameIndex)

    frames[frameIndex] = {
      rasterId: voxelRaster.id,
    }
  }

  let annotation = await primaryView.annotationManager.prepareAnnotationForCreation({
    type: 'mask',
    id: annotationId,
    data: {
      frames,
      sub_frames: {},
      segments: [[firstFrame, lastFrame + 1]],
      interpolated: false,
      interpolate_algorithm: 'linear-1.1', // Note this isn't yet used for rasters, but is required.
    },
  })

  if (!annotation) {
    throw new Error('Failed to create mask annotation')
  }

  if (!annotation.data.segments) {
    throw new Error('Annotation segments not found on VOXEL or VIDEO raster')
  }

  // Need to set this after prepareAnnotationForCreation, in order not change its internals.
  annotation.data.segments[0] = [firstFrame, lastFrame + 1]
  annotation = createAndSelectAnnotation(primaryView, annotation)

  // Call update so all the frames are registered.
  primaryView.annotationManager.updateAnnotation(annotation, {
    updatedFramesIndices,
  })
}
