export const FilterSubject = {
  FolderPath: 'folder_path',
  WorkflowStatus: 'workflow_status',
  ProcessingStatus: 'processing_status',
  AnnotationProperty: 'class_property',
  AnnotationPropertyValue: 'class_property_value',
  Assignee: 'assignee',
  CurrentAssignee: 'current_assignee',
  WorkflowStage: 'workflow_stage',
  AnnotationClass: 'annotation_class',
  Issue: 'issue',
  Id: 'id',
  ItemName: 'item_name',
  FileType: 'file_type',
  DatasetId: 'dataset_id',
  CreatedAt: 'created_at',
  UpdatedAt: 'updated_at',
  Status: 'status',
  Archived: 'archived',
  ItemPropertyValue: 'item_property_value',
} as const

export type FilterSubject = (typeof FilterSubject)[keyof typeof FilterSubject]
