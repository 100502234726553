import { isReformattedDICOMView } from '@/modules/Editor/utils/radiology/isReformattedDicomView'
import { isDicomView } from '@/modules/Editor/utils/radiology/isDicomView'
import type { View } from '@/modules/Editor/views/view'
import { doesNeedIsotropicTransformation } from '@/modules/Editor/utils/radiology/doesNeedIsotropicTransformation'

export const shouldApplyRasterScaling = (view: View): boolean => {
  if (isDicomView(view) && doesNeedIsotropicTransformation(view.fileManager.metadata)) {
    return true
  }

  return isReformattedDICOMView(view)
}
