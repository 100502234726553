import { mutations } from './dataset/mutations'
import { state } from './dataset/state'

export { getInitialState } from './dataset/state'

export default {
  namespaced: true,
  state,
  mutations,
}
