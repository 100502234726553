/**
 * @file       eventBus.ts
 * @brief      The event bus for the application.
 * @details    The event bus is a singleton which is used to communicate between components/modules.
 *             It is a communication layer between:
 *             - tools and manager
 *             - managers and views/editor
 *             - views/editor and App
 */
import EventEmitter from 'events'

import type { CameraConfig, CameraEvent, CameraOffset } from '@/modules/Editor/camera'
import type { ModelInputProps, ONNXResults } from '@/modules/Editor/sam/types'
import type {
  MaskBrushDimension,
  SubToolName,
  ToolEvent,
  ToolName,
} from '@/modules/Editor/tools/types'

import type { ImageManipulationFilter } from './imageManipulation'

import type { VtkDataManager } from '@/modules/Editor/managers/vtkDataManager'

import type { Comment, CommentThread } from '@/modules/Editor/iproviders/types'
import type { DirectionalVector } from './AnnotationData'

import type { Raster } from '@/modules/Editor/models/raster/Raster'
import type { MeasureOverlayData } from '@/modules/Editor/MeasureOverlayData'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import type { UpdatedAnnotation, DuplicateMeta } from '@/modules/Editor/serialization/types'
import type { Layout } from '@/modules/Editor/layout'
import type { Segment } from '@/modules/Editor/utils/frameExtractor'

type Listener<T = unknown, E = unknown> = (payload: T, extra: E) => void

// A singleton event bus for the application.
const eventBus = new EventEmitter()
const eventBusKeys: Set<string> = new Set()
/**
 * A slot is a wrapper around the event bus which allows for a more type-safe
 * way of emitting and listening to events.
 *
 * Every event listener on this bus suppots 2 arguments, the primary tool
 * payload, which is identicall across all events in a group, and an extra
 * payload specific to the event.
 */
const slot = <T, E = void>(
  key: string,
): {
  emit: (payload: T, extra: E) => void
  on: (listener: Listener<T, E>) => void
  off: (listener: Listener<T, E>) => void
  once: (listener: Listener<T, E>) => void
} => {
  // Ensure that the key is unique
  if (eventBusKeys.has(key)) {
    throw new Error(`Event key ${key} already exists in the eventBus`)
  }
  eventBusKeys.add(key)

  const emit = (payload: T, extra: E): void => {
    eventBus.emit(key, payload, extra)
  }

  const on = (listener: Listener<T, E>): void => {
    eventBus.on(key, listener)
  }

  const off = (listener: Listener<T, E>): void => {
    eventBus.off(key, listener)
  }

  const once = (listener: Listener<T, E>): void => {
    eventBus.once(key, listener)
  }

  return {
    emit,
    on,
    off,
    once,
  }
}
/**
 * Tool events are used to communicate between tools and the manager.
 * NOTE: Right now the manager is the shared composable (useSharedWorkviewTools).
 *
 * @example ToolEvents.submit.emit(ToolEvent, { x: 0, y: 0, w: 100, h: 100 })
 *          ToolEvents.submit.on(ToolEvent, (payload) => {...})
 */
export const ToolEvents = {
  submit: slot<
    ToolEvent,
    {
      data: ONNXResults | undefined
      clicks: ModelInputProps[]
    }
  >('tool:submit'),
  cancel: slot<ToolEvent, void>('tool:cancel'),
  changed: slot<ToolEvent, void>('tool:changed'),

  activate: slot<ToolEvent>('tool:activate'),
  deactivate: slot<ToolEvent>('tool:deactivate'),

  framePanningStateChange: slot<boolean>('tool:framePanningStateChange'),

  deactivateToolOption: slot<ToolEvent>('tool:option:deactivate'),
  deactivateToolOptions: slot<ToolEvent>('tool:options:deactivate'),
  polygonMerge: slot<ToolEvent>('tool:polygonMerge'),
  polygonSubtract: slot<ToolEvent>('tool:polygonSubtract'),
  brushDimensionChange: slot<MaskBrushDimension>('tool:changeBrushDimension'),

  directionalVectorUpdate: slot<ToolEvent, { annId: string; vector: DirectionalVector }>(
    'tool:directionalVectorUpdate',
  ),
}

/**
 * Tool manager events are used to communicate between the manager and the APP or Editor.
 * NOTE: In use by useWorkviewTools only. (we will rewrite useWorkviewTools to ToolManagerV2)
 */
export const ToolManagerEvents = {
  /**
   * ToolManager is facade for tools. On any tool submit ToolManager (useWorkviewTools)
   * should rise an event.
   */
  toolSubmittedAndReset: slot<ToolEvent, void>('toolManager:toolSubmittedAndReset'),
  /**
   * ToolManager can perform submit without reseting the tool state.
   * (just save the current tool progress).
   * NOTE: we use it to jump to the next frame
   * if SAM Edit mode is active and we are on video item.
   */
  toolSubmitted: slot<ToolEvent, void>('toolManager:toolSubmitted'),
  /**
   * On any tool cancel ToolManager (useWorkviewTools) should rise an event.
   */
  toolCanceled: slot<ToolEvent, void>('toolManager:toolCanceled'),
  frameChanged: slot<{ viewId: string }, { newIndex: number; oldIndex?: number }>(
    'toolManager:frameChanged',
  ),
  beforeToolChange: slot<ToolName | SubToolName, void>('toolManager:beforeToolChange'),
}

/**
 * Camera events are used to react to changed in the camera (Resize/Pan/Zoom).
 */
export const CameraEvents = {
  scaleChanged: slot<CameraEvent, number>('camera:scale:changed'),
  offsetChanged: slot<CameraEvent, CameraOffset>('camera:offset:changed'),
  setDimensions: slot<CameraEvent, { width: number; height: number }>('camera:dimensions:changed'),
  setWidth: slot<CameraEvent, number>('camera:width:changed'),
  setHeight: slot<CameraEvent, number>('camera:height:changed'),
  setImageSize: slot<CameraEvent, { width: number; height: number }>('camera:image:setImageSize'),
  setConfig: slot<CameraEvent, Partial<CameraConfig>>('camera:config:set'),
}

export type ViewEvent = { viewId: string; slotName?: string }

/**
 * Parental View class events that every view type can use.
 */
export const ViewEvents = {
  /**
   * Emited with the frame index of the loaded frame,
   * AFTER the frame is loaded and isplayed
   */
  currentFrameDisplayed: slot<ViewEvent, number>('currentFrame:displayed'),
  /**
   * Emitted with the frame index of the new current frame, when the
   * frame is set.
   * This then fires the loading of the frame, which later emits the displayed event.
   */
  currentFrameIndexChanged: slot<
    ViewEvent,
    {
      newIndex: number
      oldIndex?: number
    }
  >('currentFrameIndex:changed'),
  showFramesToolChanged: slot<ViewEvent, boolean>('showFramesTool:changed'),
  loadingChanged: slot<ViewEvent, boolean>('loading:changed'),
  vtkDataManagerChanged: slot<ViewEvent, VtkDataManager>('vtkDataManager:changed'),
  imageFilterChanged: slot<ViewEvent, ImageManipulationFilter>('imageFilter:changed'),
  activeChannelsChange: slot<ViewEvent, string[]>('activeChannels:changed'),
}

/**
 * View events should implement the unique events for each view type.
 *
 * Views (View, VideoView, StreamView, DicomView, ...) events repeats the inheritance tree.
 * You can call ViewEvents from VideoView but not vice versa.
 */
export const VideoViewEvents = {
  playStateChanged: slot<ViewEvent, boolean>('videoView:playStateChanged'),
}
export const StreamViewEvents = {
  readyToPlayStateChanged: slot<ViewEvent, boolean>('streamView:readyToPlayStateChanged'),
  flushRange: slot<ViewEvent, { start: number; end: number }>('streamView:flushRange'),
  segmentLoaded: slot<ViewEvent, Segment>('streamView:segmentLoaded'),
  playbackChange: slot<ViewEvent, boolean>('streamView:playbackChange'),
}

export const FramesLoaderEvents = {
  getSection: slot<{ index: number }>('frameLoader:getSection'),
  frameLoaded: slot<{ index: number; url: string; isHQ: boolean; id: string }>(
    'frameLoader:frameLoaded',
  ),
  frameInvalid: slot<{ index: number }>('frameLoader:frameInvalid'),
}

export const FrameManagerEvents = {
  firstSegmentLoaded: slot<ViewEvent>('frameManager:firstSegmentLoaded'),
  manifestLoaded: slot<ViewEvent>('frameManager:manifestLoaded'),
  framesReady: slot<ViewEvent, number[]>('frameManager:framesReady'),
  framesFlushed: slot<ViewEvent, number[]>('frameManager:framesFlushed'),
  clearFrameExtractionQueue: slot<ViewEvent>('frameManager:clearFrameExtractionQueue'),
}

export const FileManagerEvents = {
  itemFileLoaded: slot<ViewEvent>('fileManager:itemFileLoaded'),
  itemFileLoadingError: slot<ViewEvent, { error: Error }>('fileManager:itemFileLoadingError'),
}

export const WorkviewTrackerEvents = {
  reportActivity: slot<void>('reportActivity'),
}

/**
 * Temporary event to move class selection store call outside the editor.
 * To remove it we need to analyze all places where
 * we're trying to select the class inside the editor and move 'em out.
 *
 * @deprecated
 */
export const ClassEvents = {
  preselectClassId: slot<number | null>('PRESELECT_CLASS_ID'),
  changeAnnotationClass: slot<{
    annotationId: string
    newClassId: number
  }>('CHANGE_ANNOTATION_CLASS'),
}

export const LayoutEvents = {
  activeViewChanged: slot<{ newViewId: string; oldViewId?: string }>('activeView:changed'),
  visibleViewChanged: slot<{ visibleViewId: string; activeViewId: string }>(
    'layout:visibleViewChanged',
  ),

  initialised: slot<{ layout: Layout }>('layout:initialised'),
  enterFullscreen: slot<{ slotName: string; layout: Layout }>('layout:enterFullscreen'),
  exitFullscreen: slot<{ layout: Layout }>('layout:exitFullscreen'),
  enterMultiPlanarMode: slot<{ layout: Layout }>('layout:enterMultiPlanarMode'),
  exitMultiPlanarMode: slot<{ layout: Layout }>('layout:exitMultiPlanarMode'),
  setViewport: slot<{ slotName: string; layout: Layout }>(`layout:setViewport`),
  changed: slot<{ layout: Layout }>(`layout:changed`),
}

export const ActionManagerEvents = {
  doneActionsChanged: slot('actionManager:doneActionsChanged'),
  undoneActionsChanged: slot('actionManager:undoneActionsChanged'),
  action: slot('actionManager:action'),
}

export const AnnotationManagerEvents = {
  setParsedData: slot<ViewEvent>('annotationManager:setParsedData'),
  annotationsSet: slot<ViewEvent, string[]>('annotations:set'),
  annotationSet: slot<ViewEvent, UpdatedAnnotation>('annotation:set'),
  annotationPushed: slot<ViewEvent, Annotation>('annotation:pushed'),
  annotationsDelete: slot<ViewEvent, string[]>('annotations:delete'),
  annotationCreate: slot<ViewEvent, Annotation>('annotation:create'),
  annotationUpdate: slot<ViewEvent, UpdatedAnnotation>('annotation:update'),
  annotationReorder: slot<
    ViewEvent,
    {
      toReorder: { id: string }
      reference: { id: string }
      /** needed to determine if you're moving `annotationToReorder`
       * "above" or "below" `referenceAnnotation` */
      direction: 'up' | 'down'
    }
  >('annotation:reorder'),
  annotationDelete: slot<ViewEvent, string>('annotation:delete'),
  annotationDeleteMask: slot<ViewEvent, string>('annotation:deleteMask'),
  annotationSelect: slot<ViewEvent, string>('annotation:select'),
  annotationDeselect: slot<ViewEvent, string>('annotation:deselect'),
  annotationDeselectAll: slot<ViewEvent>('annotation:deselectAll'),
  annotationHighlight: slot<ViewEvent, string>('annotation:highlight'),
  annotationUnhighlight: slot<ViewEvent, string>('annotation:unhighlight'),
  annotationUnhighlightAll: slot<ViewEvent>('annotation:unhighlightAll'),
  setHiddenAnnotations: slot<ViewEvent, string[]>('annotation:setHiddenAnnotations'),
  annotationError: slot<ViewEvent>('annotation:error'),
  annotationReadonlyChange: slot<ViewEvent, { annotationId: string; readonly: boolean }>(
    'annotation:readonlyChange',
  ),
  annotationDuplicate: slot<
    ViewEvent,
    {
      sourceAnnotationId: string
      newAnnotation: Annotation
      duplicateMeta?: DuplicateMeta
    }
  >('annotation:duplicate'),
  // Temporary solution till we will move hotkey manager from the Editor
  // We need to toggle visibility that is available only from the App using
  // hotkey that available only from the Editor
  toggleAnnotationsVisibility: slot<ViewEvent>('annotation:toggleAnnotationsVisibility'),
}

/**
 * Events emitted on editor level
 *
 * The long term idea here is to make the code field mandatory and eliminate the
 * level field, but for now, it's easier to swiwtch over to this current approach.
 *
 * The code field will tells us the type of event that triggered the message and
 * allow the library user to decide the level and what to tell the end user.
 *
 * Once we get to that point, the content will more have a purpose of informing
 * the developer what happened, than purely being something the end user sees.
 *
 * As you add new messages, think about this and consider extending and using
 * the code instead of the content.
 */
export const EditorEvents = {
  message: slot<{
    content: string
    level: 'info' | 'warning' | 'error'
    code?: 'FEATURE_NOT_SUPPORTED_BY_BROWSER'
  }>('editor:message'),
  playbackSpeedUpdated: slot<number>('editor:playbackSpeedUpdated'),
  preselectedClassIdChanged: slot<number | null>('editor:preselectedClassIdChanged'),
  cleanup: slot<void>('editor:cleanup'),
  copyOrCut: slot<{ annotationId: string }>('editor:copyOrCut'),
}

export const ViewMouseEvents = {
  mousedown: slot<{ viewId: string }>('view:mousedown'),
  mouseup: slot<{ viewId: string }>('view:mouseup'),
}

/**
 * Temporary solution to reuse hotkey manager solution since it is better at the moment with
 * context and focus handling.
 * @deprecated
 * Should only be used as a temporary measure when we need hotkeys that can clash with editor
 * We will remove it when we pull hotkey management out of the editor.
 */
export const HotkeyManagerEvents = {
  key: slot<KeyboardEvent>('hotkeyManager:key'),
  cleanup: slot<void>('hotkeyManager:cleanup'),
  ready: slot<void>('hotkeyManager:ready'),
}

export const CommentManagerEvents = {
  threadsChanged: slot<ViewEvent, CommentThread[]>('commentManager:threadsChanged'),
  threadVisibilityChanged: slot<ViewEvent, CommentThread['id'][]>(
    'commentManager:threadVisibilityChanged',
  ),
  threadCreated: slot<ViewEvent, CommentThread | null>('commentManager:threadCreated'),
  threadUpdated: slot<ViewEvent, CommentThread>('commentManager:threadUpdated'),
  threadSelected: slot<ViewEvent, CommentThread>('commentManager:threadSelected'),
  threadDeselected: slot<ViewEvent>('commentManager:threadDeselected'),
  threadUpdating: slot<ViewEvent, CommentThread>('commentManager:threadUpdating'),
  threadCommentChanged: slot<ViewEvent, Comment[]>('commentManager:threadCommentChanged'),
  threadCommentCreated: slot<ViewEvent, Comment>('commentManager:threadCommentCreated'),
  threadCommentRemoved: slot<ViewEvent, Comment>('commentManager:threadCommentRemoved'),
}

export const MeasureManagerEvents = {
  measureDataChanged: slot<ViewEvent, MeasureOverlayData[]>('measureManager:measureDataChanged'),
}

export const RasterManagerEvents = {
  rasterCreated: slot<ViewEvent, Raster>('rasterManager:rastercreated'),
  rasterUpdated: slot<ViewEvent, Raster>('rasterManager:rasterupdated'),
  rasterDeleted: slot<ViewEvent, Raster['id']>('rasterManager:rasterdeleted'),
  rastersChanged: slot<ViewEvent, Raster[]>('rasterManager:rasterschanged'),
  rasterError: slot<ViewEvent>('rasterManager:rastererror'),
  videoRasterRangeUpdated: slot<ViewEvent, Raster['id']>('rasterManager:videorasterrangeupdated'),
  videoRasterKeyframeAdded: slot<
    ViewEvent,
    {
      rasterId: string
      keyframe: number
    }
  >('rasterManager:videorasterkeyframeadded'),
  videoRasterKeyframeDeleted: slot<
    ViewEvent,
    {
      rasterId: string
      keyframe: number
    }
  >('rasterManager:videorasterkeyframedeleted'),
}

/**
 * Events for Editor's edge cases.
 * number - target frame
 */
export const EditorExceptions = {
  cannotJumpToFrame: slot<number>('EditorExceptions:cannotJumpToFrame'),
  cannotUpdateReadonlyAnnotation: slot<{ annotationId: string; action: 'delete' | 'update' }>(
    'EditorExceptions:cannotUpdateReadonlyAnnotation',
  ),
}
