import type { Camera } from '@/modules/Editor/camera'
import type { ImageManipulationFilter } from '@/modules/Editor/imageManipulation'

import { imageFilterToString } from './imageFilterToString'

import { windowFunction } from '@/modules/Editor/utils/windowLevels'

/**
 * Duplicates frame from Video inside the canvas
 * to support annotations render.
 *
 * @param view
 * @returns
 */
export const renderHTMLVideo = (
  canvas: HTMLCanvasElement,
  video: HTMLVideoElement,
  imageFilter: ImageManipulationFilter,
  camera: Camera,
  width: number,
  height: number,
  windowLevelsRange: [number, number],
): void => {
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    return
  }

  const HAVE_NOTHING = 0
  if (video.readyState === HAVE_NOTHING) {
    return
  }

  ctx.imageSmoothingEnabled = !!imageFilter.isImageSmoothing
  const [dx, dy, dw, dh] = camera.drawImageParams({
    width: camera.image.width,
    height: camera.image.height,
  })
  ctx.filter = imageFilterToString(imageFilter)
  ctx.drawImage(video, dx, dy, dw, dh)

  const { windowLevels } = imageFilter
  const windowLow = windowLevels ? windowLevels[0] : windowLevelsRange[0]
  const windowHigh = windowLevels ? windowLevels[1] : windowLevelsRange[1]

  if (windowLow === windowLevelsRange[0] && windowHigh === windowLevelsRange[1]) {
    return
  }

  const frameViewport = ctx.getImageData(0, 0, width, height)
  const l = frameViewport.data.length / 4

  for (let i = 0; i < l; i++) {
    frameViewport.data[i * 4 + 0] = windowFunction(
      frameViewport.data[i * 4 + 0],
      windowLow,
      windowHigh,
    )
    frameViewport.data[i * 4 + 1] = windowFunction(
      frameViewport.data[i * 4 + 1],
      windowLow,
      windowHigh,
    )
    frameViewport.data[i * 4 + 2] = windowFunction(
      frameViewport.data[i * 4 + 2],
      windowLow,
      windowHigh,
    )
  }

  ctx.putImageData(frameViewport, 0, 0)

  ctx.filter = 'none'
  ctx.imageSmoothingEnabled = true
}
