import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import type { Bounds, Raster } from '@/modules/Editor/models/raster/Raster'
import { assertVoxelRaster } from '@/modules/Editor/models/raster/assertVoxelRaster'
import type { View } from '@/modules/Editor/views/view'
import { isMaskAnnotationEmpty } from '@/modules/Editor/plugins/mask/utils/shared/isMaskAnnotationEmpty'
import { getAnnotationSegmentFromRasterLabel } from '@/modules/Editor/plugins/mask/utils/shared/getAnnotationSegmentFromRasterLabel'
import { isVideoAnnotationData } from '@/modules/Editor/models/annotation/annotationKindValidator'

/**
 * Updates a mask annotation in 3D and the corresponding raster, and
 * publishes this to the annotationManager.
 */
export const updateMaskAnnotationIn3D = (
  primaryView: View,
  raster: Raster,
  annotation: Annotation,
  bounds: Bounds,
  depth: number,
  frameIndexes?: number[],
): void => {
  const labelIndex = raster.getLabelIndexForAnnotationId(annotation.id)

  if (labelIndex === undefined) {
    throw new Error('label not found on raster')
  }

  const voxelRaster = assertVoxelRaster(raster)
  const { currentFrameIndex } = primaryView
  const halfDepth = Math.floor(depth / 2)
  const edgeCorrection = depth % 2 === 0 ? 1 : 0
  let firstFrame = Math.max(0, currentFrameIndex - halfDepth)
  let lastFrame = Math.min(currentFrameIndex + halfDepth - edgeCorrection, voxelRaster.depth - 1)

  if (frameIndexes) {
    firstFrame = Math.min(...frameIndexes)
    lastFrame = Math.max(...frameIndexes)
  }

  const updatedFramesIndices: number[] = []
  const videoAnnotationData = annotation.data

  if (!isVideoAnnotationData(videoAnnotationData)) {
    throw new Error('Annotation data for VOXEL or VIDEO raster is invalid')
  }

  for (let frameIndex = firstFrame; frameIndex <= lastFrame; frameIndex++) {
    if (frameIndexes && !frameIndexes.includes(frameIndex)) {
      continue
    }

    const rasterBufferAccessor = voxelRaster.getBufferForEdit(frameIndex)
    const isEmptyOnFrame = isMaskAnnotationEmpty(labelIndex, rasterBufferAccessor, bounds)

    if (isEmptyOnFrame) {
      voxelRaster.deleteLabelOnKeyframe(labelIndex, frameIndex)
      voxelRaster.deleteVideoBoundsForLabelIndexForFrame(labelIndex, frameIndex)
    } else {
      const annotationData = {
        rasterId: voxelRaster.id,
      }

      videoAnnotationData.frames[frameIndex] = annotationData

      if (!voxelRaster.frameBuffers[frameIndex]) {
        voxelRaster.createNewKeyframe(frameIndex)
      }

      voxelRaster.setVideoBoundsForLabelIndexForFrame(labelIndex, frameIndex, bounds)
      voxelRaster.setLabelOnKeyframe(labelIndex, frameIndex)
    }

    updatedFramesIndices.push(frameIndex)
  }

  if (!videoAnnotationData.segments) {
    throw new Error('Annotation segments not found on VOXEL or VIDEO raster')
  }

  // We should not rely on mask annotation segments, but the right-hand sidebar does.
  // TODO: we should update the sidebar so that it checks rasterRange instead
  videoAnnotationData.segments[0] = getAnnotationSegmentFromRasterLabel(voxelRaster, labelIndex)
  primaryView.annotationManager.updateAnnotationData(annotation, videoAnnotationData)

  primaryView.annotationManager.updateAnnotation(annotation, {
    updatedFramesIndices: updatedFramesIndices,
  })
}
