import { EventEmitter } from 'events'
import { v4 as uuidv4 } from 'uuid'

import type { IPoint } from '@/modules/Editor/point'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import type { View } from '@/modules/Editor/views/view'

import type { RasterBufferAccessor } from './RasterBufferAccessor'
import type { RasterTypes } from './rasterTypes'
import type { PartialRecord } from '@/core/helperTypes'

export type Bounds = {
  topLeft: IPoint
  bottomRight: IPoint
}
export type BoundsPerLabelIndex = Record<string, Bounds>

/**
 * Defines a region on the Raster's cachedCanvas that needs to be re-rendered.
 */
export type InvalidatedRasterRegion = {
  xMin: number
  xMax: number
  yMin: number
  yMax: number
}

/**
 * Holds the data needed to recreate a raster mask.
 * `denseRLE` can either by an array of numbers (ImageRaster) or an object with frame indexes as
 * keys and array of numbers for values (VideoRaster/VoxelRaster).
 */
export type RasterDataSnapshot = {
  denseRLE: number[] | PartialRecord<number, number[]>
  maskAnnotationIdsMapping: Map<string, number>
  maskAnnotationClassIdsMapping: Map<string, number>
  labelIndexToClassIdMapping: Map<number, number>
  labelIndexToAnnotationIdMapping: Map<number, string>
  labelsInSnapshot?: number[]
}

/**
 * Note as we have chose to use Uint8Arrays on the frontend to limit memory usage,
 * we currently support 255 labels/annotations on a single raster. If we need more
 * we will need to use 2 bytes/pixel and then we'll be able to support 65535
 * segments. But this will come at higher memory usage for every use case unless we.
 * Smartly choose/migrate based on the number of segments.
 */
const maxLabels: number = 255

/**
 * An object which contains the raw data used to display a raster mask.
 * Each raster has its own id and references a particular file to which
 * it is associated. The size of the raster is automatically derived
 * from the file it is associated with.
 */
export abstract class Raster extends EventEmitter {
  abstract readonly type: RasterTypes
  public id: string
  public fileId: string
  public readonly width: number
  public readonly height: number
  public readonly size: number
  protected invalidated: boolean
  protected _invalidatedRegion: InvalidatedRasterRegion
  protected _labelIndexToAnnotationId: Map<number, string> = new Map()
  protected _annotationIdToLabelIndex: Map<string, number> = new Map()
  protected _annotationIdToClassId: Map<string, number> = new Map()
  protected _labelIndexToClassId: Map<number, number> = new Map()
  public cachedCanvas?: HTMLCanvasElement
  protected _labelsOnRaster: Set<number> = new Set()
  protected _inProgressAnnotations: Map<string, Annotation> = new Map()
  public readonly view: View

  constructor(view: View) {
    super()
    const currentFile = view.fileManager.file

    if (currentFile.metadata === undefined) {
      throw new Error('Need file in view to create raster')
    }

    const fileId = currentFile.id
    this.view = view

    this.id = uuidv4()
    this.fileId = fileId

    const { metadata } = currentFile
    const width = metadata.pixelWidth ?? metadata.width
    const height = metadata.pixelHeight ?? metadata.height
    const size = width * height

    this.width = width
    this.height = height
    this.size = size
    this.invalidated = true
    this._invalidatedRegion = {
      xMin: 0,
      xMax: width - 1,
      yMin: 0,
      yMax: height - 1,
    }

    this.cachedCanvas = this.createCanvas(this.width, this.height)
  }

  // Methods to be implemented by derived classes
  public abstract getActiveBufferForRender(): RasterBufferAccessor | undefined
  public abstract getActiveBufferForEdit(): RasterBufferAccessor
  public abstract getLabelRange(labelIndex: number): [number, number]
  public abstract deleteLabelFromRaster(labelIndex: number): void
  public abstract takeSnapshot(frameIndexes?: number[]): RasterDataSnapshot
  public abstract cleanup(): void
  protected abstract deleteBoundsForLabelIndex(labelIndex: number): void

  /** can be used to store a temporary snapshot of the raster data **/
  public snapshot: RasterDataSnapshot | undefined

  public clearSnapshot(): void {
    this.snapshot = undefined
  }

  get currentFrameIndex(): number {
    return this.view.currentFrameIndex
  }

  get invalidatedRegion(): {
    xMin: number
    xMax: number
    yMin: number
    yMax: number
  } {
    return { ...this._invalidatedRegion }
  }

  /**
   * Finds and returns the lowest unused label index that isn't zero,
   * which is reserved for empty/unlabelled. Note this isn't just one
   * greater than the number of labels, as a slot might have opened
   * up from deletion.
   * @returns The label index
   */
  public getNextAvailableLabelIndex(): number {
    const labelsOnRaster = this._labelsOnRaster

    for (let i = 1; i < maxLabels; i++) {
      if (!labelsOnRaster.has(i)) {
        return i
      }
    }

    throw new Error(`Reached max available segments, currently support ${maxLabels}`)
  }

  public getLabelIndexForAnnotationId(annotationId: string): number | undefined {
    return this._annotationIdToLabelIndex.get(annotationId)
  }

  /**
   * Finds the label index of the mask annotation on the raster,
   * or returns the next available label index if the annotation is new.
   * @param existingAnnotation The optional existing annotation to query.
   * @returns The appropriate label index for the raster.
   */
  public getLabelIndexForAnnotation(existingAnnotation?: Annotation): number {
    if (existingAnnotation !== undefined) {
      const labelIndex = this.getLabelIndexForAnnotationId(existingAnnotation.id)

      if (labelIndex === undefined) {
        throw new Error('Exisitng annotation has no mapping to raster.')
      }

      return labelIndex
    }

    return this.getNextAvailableLabelIndex()
  }

  public getAnnotationIdToClassIdMapping(): Map<string, number> {
    return this._annotationIdToClassId
  }

  public getAnnotationToLabelMapping(): Map<string, number> {
    return this._annotationIdToLabelIndex
  }

  public getClassIdForLabelIndex(labelIndex: number): number | undefined {
    return this._labelIndexToClassId.get(labelIndex)
  }

  public getLabelIndexToClassIdMapping(): Map<number, number> {
    return this._labelIndexToClassId
  }

  public getAnnotationMapping(labelIndex: number): string | undefined {
    return this._labelIndexToAnnotationId.get(labelIndex)
  }

  public getLabelIndexToAnnotationMapping(): Map<number, string> {
    return this._labelIndexToAnnotationId
  }

  public setAnnotationMapping(labelIndex: number, annotationId: string, classId: number): void {
    this._labelIndexToAnnotationId.set(labelIndex, annotationId)
    this._annotationIdToLabelIndex.set(annotationId, labelIndex)
    this._annotationIdToClassId.set(annotationId, classId)
    this._labelIndexToClassId.set(labelIndex, classId)
    this._labelsOnRaster.add(labelIndex)
  }

  public deleteAnnotationMapping(labelIndex: number): void {
    const annotationId = this.getAnnotationMapping(labelIndex)

    if (annotationId !== undefined) {
      this._annotationIdToLabelIndex.delete(annotationId)
      this._annotationIdToClassId.delete(annotationId)
    }

    this._labelIndexToAnnotationId.delete(labelIndex)
    this._labelIndexToClassId.delete(labelIndex)
    this._labelsOnRaster.delete(labelIndex)

    this.deleteBoundsForLabelIndex(labelIndex)
  }

  public clearAnnotationMappings(): void {
    this._labelIndexToAnnotationId.clear()
    this._annotationIdToLabelIndex.clear()
    this._annotationIdToClassId.clear()
    this._labelIndexToClassId.clear()
    this._labelsOnRaster = new Set()
  }

  /**
   * Annotation mapping methods:
   * Used to store temporary annotations for rendering during annotation creation
   * (e.g.) during a brush stroke, or during some preview of a complex raster annotation tool.
   */
  public getInProgressAnnotation(annotationId: string): Annotation | undefined {
    return this._inProgressAnnotations.get(annotationId)
  }

  public setInProgressAnnotation(annotation: Annotation): void {
    this._inProgressAnnotations.set(annotation.id, annotation)
  }

  public clearInProgressAnnotations(): void {
    this._inProgressAnnotations.clear()
  }

  public get labelsOnRaster(): number[] {
    return Array.from(this._labelsOnRaster)
  }

  public get annotationIdsOnRaster(): string[] {
    const labelsOnRaster = this.labelsOnRaster

    const annotationIdsOnRaster: string[] = []

    labelsOnRaster.forEach((labelIndex: number) => {
      const annotationId = this.getAnnotationMapping(labelIndex)

      if (annotationId !== undefined) {
        annotationIdsOnRaster.push(annotationId)
      }
    })

    return annotationIdsOnRaster
  }

  public invalidateAll(): void {
    this.invalidate(0, this.width - 1, 0, this.height - 1)
  }

  /**
   * Invalidates a region of the raster being displayed (in derived implementations
   * this may be an image frame, a video frame, etc).
   */
  public invalidate(xMin: number, xMax: number, yMin: number, yMax: number): void {
    // Clip bounds to image if we somehow get an invalidation region that goes
    // partially offscreen.
    xMin = Math.max(0, xMin)
    xMax = Math.min(this.width - 1, xMax)
    yMin = Math.max(0, yMin)
    yMax = Math.min(this.height - 1, yMax)

    // Check if the region actually covers at least one pixel.
    if (xMax - xMin < 0 || yMax - yMin < 0) {
      return
    }

    // Invalidate the region,
    this._invalidatedRegion = {
      xMin,
      xMax,
      yMin,
      yMax,
    }

    this.invalidated = true

    const rasterLayer = this.view.rasterAnnotationLayer

    rasterLayer.changed()
    rasterLayer.render()
  }

  public isInvalidated(): boolean {
    return this.invalidated
  }

  /**
   * To be called when the invalidation of the data has been consumed by the renderer
   */
  public clearInvalidation(): void {
    this.invalidated = false
  }

  /**
   * Frees up memory when we no longer need the cached canvas
   */
  public freeMemory(): void {
    this.invalidateAll()
  }

  protected getMaskAnnotationsOnRaster = (): Annotation[] => {
    const { labelsOnRaster, view } = this
    const { annotationManager } = view

    const annotations: Annotation[] = []

    labelsOnRaster.forEach((labelIndex) => {
      const annotationId = this.getAnnotationMapping(labelIndex)

      if (!annotationId) {
        return
      }

      const annotation = annotationManager.getAnnotation(annotationId)

      if (annotation) {
        annotations.push(annotation)
      }
    })

    return annotations
  }

  protected createCanvas(x: number, y: number): HTMLCanvasElement {
    const canvas = document.createElement('canvas')

    canvas.width = x
    canvas.height = y

    return canvas
  }
}
