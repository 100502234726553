import type { View } from '@/modules/Editor/views/view'
import { ViewTypes } from '@/modules/Editor/views/viewTypes'
import { isDicomView } from '@/modules/Editor/utils/radiology/isDicomView'

import { ImageRaster } from './ImageRaster'
import type { Raster } from './Raster'
import { VideoRaster } from './VideoRaster'
import { VoxelRaster } from './VoxelRaster'
import { doesNeedIsotropicTransformation } from '@/modules/Editor/utils/radiology/doesNeedIsotropicTransformation'

/**
 * Created a raster of the appropriate type based on the type of the view.
 */
export const createRaster = (view: View): Raster => {
  if (isDicomView(view)) {
    const { isPrimary, isRadiologicalVolumeView } = view

    if (!isPrimary) {
      throw new Error('Cannot create a raster on a reformatted view, this should not be hit')
    }

    if (doesNeedIsotropicTransformation(view.fileManager.metadata)) {
      return new VoxelRaster(view)
    }

    return isRadiologicalVolumeView ? new VoxelRaster(view) : new VideoRaster(view)
  }
  const hasFrames =
    view.type === ViewTypes.VIDEO ||
    view.type === ViewTypes.STREAM ||
    view.type === ViewTypes.LEGACY_DICOM
  if (hasFrames) {
    return new VideoRaster(view)
  }

  if (view.type === ViewTypes.IMAGE) {
    return new ImageRaster(view)
  }

  throw new Error('Unsupported view type for raster')
}
