import {
  JUMP_TO_FRAME_TIMELINE_FRAME_CLICK,
  GET_RESPONSE_WITH_ANNOTATIONS,
  LAYER_BAR_ITEM_MOUNTED,
  OVERLAYS_HTML_SUB_ANNOTATION_RENDERED,
  ALL_OVERLAYS_RENDERED,
  PAGINATION_GET_RESPONSE_WITH_ANNOTATION_METADATA,
  RENDER_IMAGE_AND_ANNOTATIONS,
  SYNC_VIDEO_PLAYBACK_CALL,
  RENDERED_CALL,
  APP_INIT,
  FIRST_ANNOTATION_RENDERED,
  ANNOTATION_CREATE_FUNCTION_CALL,
  ACTIVE_ANNOTATION_RENDERED,
} from './keys'

const measure = (name: string, startKey: string, endKey: string): void => {
  try {
    const m = performance.measure(name, startKey, endKey)
    console.info(m.name, m.duration.toFixed(2), 'ms')
  } catch {
    console.info(name, "can't be measured, because", startKey, 'or', endKey, 'is not found')
  }
}

const clearMeasures = (): void => {
  performance.clearMeasures()
}

const clearMarks = (key?: string): void => {
  performance.clearMarks(key)
}

/**
 * Measure the time it takes to render the layer bar item
 * and print the result in the console.
 *
 * When PAGINATION is disabled,
 * it will print the time from annotations response to the layer bar item mounted.
 */
const measureLayerBarItemRender = (): void => {
  measure('layer-bar:item:render', GET_RESPONSE_WITH_ANNOTATIONS, LAYER_BAR_ITEM_MOUNTED)
}

/**
 * Measure the time it takes to render the layer bar item
 * and print the result in the console.
 *
 * When PAGINATION is enabled,
 * it will print the time from metadata response to the layer bar item mounted.
 */
const measureLayerBarItemRenderWithPagination = (): void => {
  measure(
    'PAGINATION:layer-bar:item:render',
    PAGINATION_GET_RESPONSE_WITH_ANNOTATION_METADATA,
    LAYER_BAR_ITEM_MOUNTED,
  )
}

/**
 * Measure the time it takes to render all the overlays on the canvas
 * after jumping to a frame and print the result in the console.
 *
 * To get the resutl you need to jump to a frame first.
 */
const measureOverlaysRenderingAfterJumpToFrame = (): void => {
  measure(
    'from jump to frame to all overlays rendered',
    JUMP_TO_FRAME_TIMELINE_FRAME_CLICK,
    ALL_OVERLAYS_RENDERED,
  )
}

/**
 * Measure the time it takes to render the sub annotations (HTML) overlays
 * after jumping to a frame and print the result in the console.
 *
 * To get the resutl you need to jump to a frame first.
 */
const measureSubAnnotationsHTMLOverlaysRenderingAfterJumpToFrame = (): void => {
  measure(
    'from jump to frame to sub annotations (HTML) overlays rendered',
    JUMP_TO_FRAME_TIMELINE_FRAME_CLICK,
    OVERLAYS_HTML_SUB_ANNOTATION_RENDERED,
  )
}

const measureRenderingSingleSlotImageAndAnnotationsRendered = (): void => {
  measure(
    'from jump to frame to actual rendering',
    SYNC_VIDEO_PLAYBACK_CALL,
    RENDER_IMAGE_AND_ANNOTATIONS,
  )
}

const measureRenderingOfAllSlotsAfterJumpToFrame = (): void => {
  measure(
    'from jump to frame to actual rendering of all slots',
    SYNC_VIDEO_PLAYBACK_CALL,
    RENDERED_CALL,
  )
}

/**
 * Measure the time it takes to render the first annotation on the canvas.
 *
 * To get the result you need to have at least one annotation.
 * 1. Refresh the page.
 * 2. Wait for the first annotation to be rendered.
 * 3. Call this function.
 */
const measureTimeToFirstAnnotationRendered = (): void => {
  measure('from app init to the first annotation rendered', APP_INIT, FIRST_ANNOTATION_RENDERED)
}

/**
 * Measure the time it takes to create an annotation and render it on the canvas.
 *
 * To get the result you need to create an annotation and call this function.
 */
const measureAnnotationCreatedTime = (): void => {
  measure(
    'from annotation created to rendered',
    ANNOTATION_CREATE_FUNCTION_CALL,
    ACTIVE_ANNOTATION_RENDERED,
  )
}

/* REAL FPS SECTION: START */

let realFPSInterval: ReturnType<typeof setInterval> | null = null
let realFPS: number = 0
let hasRealFPSDisplay = true
export const setRealFPS = (fps: number): void => {
  realFPS = fps
}

export const startRealFPS = (): void => {
  hasRealFPSDisplay = true
  realFPSInterval = setInterval(() => {
    // eslint-disable-next-line no-console
    console.clear()
    console.info(`Real FPS: ${realFPS}`)
  }, 100)
}

export const stopRealFPS = (): void => {
  hasRealFPSDisplay = false
  realFPSInterval && clearInterval(realFPSInterval)
  realFPSInterval = null
}

export const isRealFPSDisplayOn = (): boolean => hasRealFPSDisplay

/* REAL FPS SECTION: END */

/**
 * Initialize performance measures
 *
 * to get results in the browser console, run the following command:
 * `__MEASURES__.FUNCTION_NAME()`
 */
export const initMeasures = (): void => {
  window.__MEASURES__ = {
    measureLayerBarItemRender,
    measureLayerBarItemRenderWithPagination,
    measureOverlaysRenderingAfterJumpToFrame,
    measureSubAnnotationsHTMLOverlaysRenderingAfterJumpToFrame,
    measureRenderingSingleSlotImageAndAnnotationsRendered,
    measureRenderingOfAllSlotsAfterJumpToFrame,
    measureTimeToFirstAnnotationRendered,
    measureAnnotationCreatedTime,
    startRealFPS,
    stopRealFPS,
    setRealFPS,
    realFPS,
    clearMeasures,
    clearMarks,
  }
}
