import { EventEmitter } from 'events'

import type {
  LoadedImageWithTiles,
  TileCacheImage,
} from '@/modules/Editor/models/annotation/LoadedImageWithTiles'
import type { Tile } from '@/modules/Editor/models/tiler'
import { createTiler } from '@/modules/Editor/models/tiler'
import type { TiledView } from '@/modules/Editor/views/tiledView'

export enum Events {
  TILES_LOADED = 'tiles:loaded',
}

type TilePositionKey = string

export class TilesManager extends EventEmitter {
  private tilesUrls: { [k: TilePositionKey]: { url: string; slotName: string }[] } = {}
  private tiles: { [k: TilePositionKey]: TileCacheImage } = {}
  private tiler = createTiler()

  constructor(private view: TiledView) {
    super()
  }

  public get imageWidth(): number {
    return this.view.fileManager.imageWidth || 0
  }

  public get imageHeight(): number {
    return this.view.fileManager.imageHeight || 0
  }

  private get tiledImage(): Partial<LoadedImageWithTiles> {
    return {
      width: this.imageWidth,
      height: this.imageHeight,

      /** Only available if the full image has been loaded (not just metadata) */
      data: this.view.currentFrame,

      levels: this.view.fileManager.metadata?.levels,

      cache: this.tiles,
    }
  }

  /**
   * Builds a list of tile urls that needs to be loaded for each channel, given the current
   * zoom level.
   * Part of the list is also a reference to the file slot name, so that we can retrieve image
   * filters based on it.
   * Each tile url and slot name will be part of an array assigned to a particular key,
   * identifying the exact location and zoom level
   */
  public async getTiles(
    tiles: { x: number; y: number; z: number }[],
  ): Promise<{ [k in TilePositionKey]: { slotName: string; url: string }[] }> {
    // Default scenario, using the view file manager
    let tileSources = [
      {
        itemId: this.view.fileManager.item.id,
        slotName: this.view.fileManager.file.slot_name,
      },
    ]
    // In case of channels, use the channel file manager
    if (this.view.activeChannels.length > 0) {
      tileSources = this.view.activeChannels.map((channel) => ({
        itemId: channel.fileManager.item.id,
        slotName: channel.fileManager.file.slot_name,
      }))
    }

    // loads all tile sources with reference to the slot name
    const tilesData = await Promise.all(
      tileSources.map(async (tileSource) => {
        const sourceTiles = await this.view.editor.loadTiles(
          tileSource.itemId,
          tileSource.slotName,
          tiles,
        )
        return {
          slotName: tileSource.slotName,
          sourceTiles,
        }
      }),
    )

    for (const tileData of tilesData) {
      const sourceTileData = tileData.sourceTiles
      if (!sourceTileData) {
        continue
      }
      // group all tile urls per zoom level, and add information about the slot name for each of them
      for (const key of Object.keys(sourceTileData)) {
        if (!this.tilesUrls[key]) {
          this.tilesUrls[key] = []
        }
        if (!this.tilesUrls[key].find((data) => data.url === sourceTileData[key])) {
          this.tilesUrls[key].push({
            url: sourceTileData[key],
            slotName: tileData.slotName,
          })
        }
      }
    }

    this.emit(Events.TILES_LOADED)

    return this.tilesUrls
  }

  public getVisibleTiles(neighbourTiles = false, throttled = true): Tile[] {
    return this.tiler.getVisibleTiles(this.tiledImage, this.view, neighbourTiles, throttled, () => {
      this.view.mainLayer.changed()
    })
  }

  /**
   * Used to re-render tiles that are already loaded.
   * This is normally used to apply changes in channel filters
   */
  public reloadVisibleTiles(): void {
    this.tiler.reloadVisibleTiles(this.tilesUrls, this.tiledImage, this.view.imageFilters, () => {
      this.view.mainLayer.changed()
    })
  }

  public cleanup(): void {
    this.tilesUrls = {}
    this.tiles = {}
  }
}
