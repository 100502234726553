import { addBreadcrumb, captureException } from '@/services/sentry'
import type { V2WorkflowCommandPayload } from '@/store/types/V2WorkflowCommandPayload'
import type { V2WorkflowCommandResponse } from '@/store/types/V2WorkflowCommandResponse'
import { post } from '@/backend/api'
import type { ParsedError, ErrorResponse } from '@/backend/error'
import { errorMessages, isErrorResponse, parseError } from '@/backend/error'
import type { ApiResult } from './types'
import type { operations } from './api'

type Params = {
  commands: Omit<V2WorkflowCommandPayload, 'id' | 'user_id'>[]
  teamSlug: string
  datasetItemId: string
}

type Response = Promise<ApiResult<V2WorkflowCommandResponse>>

const parseCommandError = (
  errorResponse: ErrorResponse,
  commands: Omit<V2WorkflowCommandPayload, 'id' | 'user_id'>[],
): ParsedError => {
  if (commands.length) {
    const command = commands[0]
    if (command.type === 'create_annotation') {
      return parseError(errorResponse, errorMessages.ANNOTATION_CREATE)
    }
    if (command.type === 'update_annotation') {
      return parseError(errorResponse, errorMessages.ANNOTATION_UPDATE)
    }
    if (command.type === 'delete_annotation') {
      return parseError(errorResponse, errorMessages.ANNOTATION_DELETE)
    }
    if (command.type === 'delete_annotations') {
      return parseError(errorResponse, errorMessages.ANNOTATIONS_DELETE)
    }
    if (command.type === 'copy_annotation') {
      return parseError(errorResponse, errorMessages.ANNOTATION_COPY)
    }
    if (command.type === 'reorder_annotation') {
      return parseError(errorResponse, errorMessages.ANNOTATION_REORDER)
    }
    if (command.type === 'shift_sequence_annotation_segments') {
      return parseError(errorResponse, errorMessages.ANNOTATION_SEQUENCE_SHIFT)
    }
  }

  return parseError(errorResponse, {
    401: [
      "You're not alowed to perform this action.",
      'Your session might have expired.',
      'Please try signing in again.',
    ].join(' '),
    default: "Something's wrong. Unable to perform this action. Try refreshing your browser",
  })
}

type FailedCommandResponse =
  operations['workflows-run-command']['responses'][422]['content']['application/json']

/**
 * Sends a batch of commands to a v2 workflow item.
 *
 * All commands must be valid in order for any of them to execute.
 * If valid, they enqueue in the given order.
 *
 * Note that if any of the commands is given a delay, then it might execute out
 * of order with the other commands.
 */
export const sendV2Commands = async (params: Params): Response => {
  const { teamSlug, datasetItemId } = params
  const path = `v2/teams/${teamSlug}/items/${datasetItemId}/commands`

  try {
    const response = await post<V2WorkflowCommandResponse>(path, { commands: params.commands })
    return { data: response.data, ok: true }
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }

    const status = error.response ? error.response.status : undefined

    if (status === 422) {
      const responseData = error.response?.data as unknown as FailedCommandResponse
      addBreadcrumb({
        category: 'darwin commands',
        data: {
          path,
          errorCode: error.code,
          errorStatusCode: status,
          errorMessage: error.message,
          totalCommands: params.commands.length,
          // we want to limit how much we log, but for commands, we do want to make sure the errors are logged.
          // we expect 1 command in the array, so there is no risk of 1800 being to much.
          failedCommands: responseData.failed_commands.map((c) => ({
            errors: JSON.stringify(c.errors),
            input_data: JSON.stringify(c.input_data).slice(0, 1800),
          })),
        },
        level: 'error',
      })
    }

    const is4xxStatus = status && status >= 400 && status <= 499
    // tracking command errors are handled in a different place and
    // 422 cannot be fully prevented with those
    const is422RequestTracking = params.commands[0]?.type === 'request_tracking' && status === 422
    // 401 is perfectly fine
    const is401 = status === 401

    if (is4xxStatus && !is401 && !is422RequestTracking) {
      error.name = `SendCommandError: ${params.commands[0]?.type}`
      error.message = `Command request for '${params.commands[0]?.type}' failed with status code ${error.code}`
      captureException(error)
    }
    return { ...parseCommandError(error, params.commands), ok: false }
  }
}
