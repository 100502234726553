import throttle from 'lodash/throttle'

import type { Camera } from '@/modules/Editor/camera'
import type { TiledLevelMap } from '@/modules/Editor/metadata'
import { getWindowLevelsRange } from '@/modules/Editor/utils/windowLevels'
import type {
  LoadedImageWithTiles,
  TileCacheImage,
} from '@/modules/Editor/models/annotation/LoadedImageWithTiles'
import type { TiledView } from '@/modules/Editor/views/tiledView'
// eslint-disable-next-line boundaries/element-types
import type { RenderableImage } from '@/store/modules/workview/types'
import type { BlendImageData } from '@/modules/Editor/utils/resolveBlendedRenderableImage'
import { resolveBlendedRenderableImage } from '@/modules/Editor/utils/resolveBlendedRenderableImage'
import type { PartialRecord } from '@/core/helperTypes'
import type { ImageManipulationFilter } from '@/modules/Editor/imageManipulation'

export type Tile = {
  rx: number
  ry: number
  cx: number
  cy: number
  w: number
  h: number
  key: string
  z: number
  priority: number
  image: () => TileCacheImage
}

export type TileRequestEntry = {
  x: number
  y: number
  z: number
  priority: number
  request: Promise<{ url: string; slotName: string }[]>
  resolve: (urlsData: { url: string; slotName: string }[]) => void
  reject: (error: unknown) => void
}

// maybe move out?
type RepaintCallback = () => void

const HIGH_PERFORMANCE = true

const CACHE_IMAGE_SIZE = HIGH_PERFORMANCE ? 150 : 15

const clamp = (value: number, min: number, max: number): number => {
  if (value > max) {
    return max
  }
  if (value < min) {
    return min
  }
  return value
}

const imageKey = (x: number, y: number, z: number): string => `#${z}#${y}#${x}`

export const isRenderableImage = (image: TileCacheImage): image is RenderableImage => {
  if (!image) {
    return false
  }
  if (typeof image === 'string' || !('data' in image)) {
    return false
  }
  return (image as RenderableImage).data instanceof HTMLImageElement
}

/**
 * returns a renderable image, blending all tile urls together, with passed window levels
 */
export const renderTileFromUrls = async (
  urlsData: { url: string; slotName: string }[],
  imageFilters: PartialRecord<string, ImageManipulationFilter>,
): Promise<RenderableImage | undefined> => {
  const tileImageBlobs = await Promise.all(
    urlsData.map(({ url, slotName }) =>
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => ({
          blob,
          slotName,
        })),
    ),
  )
  const tileRenderableImages: BlendImageData[] = await Promise.all(
    tileImageBlobs.map(
      ({ blob, slotName }) =>
        new Promise<BlendImageData>((resolve, reject) => {
          const img = new Image()
          img.src = URL.createObjectURL(blob)
          img.onload = (): void => {
            img.onload = null
            img.onerror = null
            resolve({
              image: {
                data: img,
                rawData: null,
                transformedData: null,
                lastWindowLevels: getWindowLevelsRange(),
                lastColorMap: null,
              },
              windowLevels: imageFilters[slotName]?.windowLevels || getWindowLevelsRange(),
            })
          }
          img.onerror = (): void => {
            reject()
          }
        }),
    ),
  )

  return resolveBlendedRenderableImage(tileRenderableImages)
}

/**
 * Schedule tiles to load when the url for them is received
 *
 * Loading is done by creating an HTML Image element and setting
 * its src attribute to the value of the resolved URL
 */
export const scheduleTileLoad = async (
  cache: { [k: string]: TileCacheImage },
  entry: TileRequestEntry,
  imageFilters: PartialRecord<string, ImageManipulationFilter>,
  requestRepaint: RepaintCallback,
): Promise<void> => {
  const key = imageKey(entry.x, entry.y, entry.z)
  let urls: { url: string; slotName: string }[]

  try {
    urls = await entry.request
  } catch {
    cache[key] = 'error'
    return
  }

  const blendedImage = await renderTileFromUrls(urls, imageFilters)
  if (blendedImage) {
    cache[key] = blendedImage
  }

  requestRepaint()
}

export const createTiler = (): {
  getVisibleTiles: (
    image: Partial<LoadedImageWithTiles>,
    view: TiledView,
    loadNeighbourTiles: boolean,
    throttled: boolean,
    requestRepaint: RepaintCallback,
  ) => Tile[]
  reloadVisibleTiles: (
    urls: { [k: string]: { url: string; slotName: string }[] },
    image: Partial<LoadedImageWithTiles>,
    imageFilters: PartialRecord<string, ImageManipulationFilter>,
    requestRepaint: RepaintCallback,
  ) => Promise<void>
} => {
  const tileRequestEntriesMap: { [id: number]: TileRequestEntry[] } = {}

  /**
   * Retrieve tiles for a specified zoom level
   */
  const getTilesForZoomLevel = (
    levels: TiledLevelMap,
    cache: { [k: string]: TileCacheImage },
    camera: Camera,
    zoomLevel: number,
    loadNeighbourTiles = false,
  ): Tile[] => {
    const level = levels[zoomLevel]
    if (!level) {
      return []
    }
    const tileW = level.tile_width
    const tileH = level.tile_height

    const unitsPerPixel = level.pixel_ratio

    // how many tiles does this zoom level have?
    const numHorizontalTiles = level.x_tiles
    const numVerticalTiles = level.y_tiles

    const offset = camera.getOffset()
    const scale = camera.scale
    const canvasWidth = camera.width
    const canvasHeight = camera.height

    // left/right/top/bottom - most tile indexes for the current zoom level
    let left = Math.floor(offset.x / (tileW * scale * unitsPerPixel))
    let right = Math.ceil((canvasWidth + offset.x) / (tileW * scale * unitsPerPixel)) - 1
    let top = Math.floor(offset.y / (tileH * scale * unitsPerPixel))
    let bottom = Math.ceil((canvasHeight + offset.y) / (tileH * scale * unitsPerPixel)) - 1

    if (loadNeighbourTiles) {
      top = Math.max(0, top - 1)
      right = Math.min(right + 1, numHorizontalTiles)
      bottom = Math.min(bottom + 1, numVerticalTiles)
      left = Math.max(0, left - 1)
    }

    // useful for debugging:
    // console.debug(`zoom: ${zoomLevel}, tiles X: ${left}::${right}, tiles Y: ${top}::${bottom}`)

    const visibleTiles = []
    for (let x = left; x <= right; x++) {
      if (x < 0 || x >= numHorizontalTiles) {
        continue
      }

      for (let y = top; y <= bottom; y++) {
        if (y < 0 || y >= numVerticalTiles) {
          continue
        }

        const key = imageKey(x, y, zoomLevel)

        visibleTiles.push({
          rx: x,
          ry: y,
          z: zoomLevel,
          cx: -camera.getOffset().x + x * tileW * scale * unitsPerPixel,
          cy: -camera.getOffset().y + y * tileH * scale * unitsPerPixel,
          w: tileW * scale * unitsPerPixel,
          h: tileH * scale * unitsPerPixel,
          key: key,
          priority: !HIGH_PERFORMANCE
            ? 1
            : x === left || x === right || y === top || y === bottom
              ? 2
              : 1,
          image: () => cache[key],
        })
      }
    }

    return visibleTiles.sort((a, b) => {
      if (a.priority > b.priority) {
        return 1
      }
      if (a.priority < b.priority) {
        return -1
      }
      return 0
    })
  }

  /**
   * Retrieve max zoom level for image
   */
  const getMaxImageZoomLevel = (levels: LoadedImageWithTiles['levels']): number => {
    const maxLevelStr = Object.keys(levels || {})
      .filter((key) => key !== 'base_key')
      .reduce((a, b) => (a > b ? a : b))

    return parseInt(maxLevelStr)
  }

  /**
   * Capture image loading requests so the url can be requested
   * from the backend before being loaded into the cache,
   *
   * This is done by putting all request inside tileRequestEntriesMap,
   * using the zoomLevel as key, together with a promise to resolve them.
   *
   */
  const createTileRequestEntry = (
    cache: { [k: string]: TileCacheImage },
    tile: Tile,
  ): TileRequestEntry | void => {
    const { rx: x, ry: y, z, key, priority } = tile
    const data = cache[key]
    // don't load if the image is already loading or has been loaded
    if (data === 'loading' || cache[key] !== undefined) {
      return
    }
    cache[key] = 'loading'

    const entry: Partial<TileRequestEntry> = { x, y, z, priority }

    entry.request = new Promise((resolve, reject) => {
      entry.resolve = resolve
      entry.reject = reject
    })

    return entry as TileRequestEntry
  }

  /**
   * Cleanup image tile cache.
   *
   * Keeps at least <CACHE_IMAGE_SIZE> images in cache.
   *
   * For amounts > <CACHE_IMAGE_SIZE> images, any cache keys which aren't part of the listed
   * visible tiles are discadred, unless they are currently being loaded or
   * haven't started loading (undefined) yet.
   *
   * Errored requests are also cleaned up, so they can be retried
   *
   * NOTE: 1 image ~ 6 mb of ram.
   */
  const cleanUpTileCache = (cache: { [k: string]: TileCacheImage }, visibleTiles: Tile[]): void => {
    if (Object.keys(cache).length <= CACHE_IMAGE_SIZE + visibleTiles.length) {
      return
    }

    const visibleKeys = new Set(visibleTiles.map((tile) => tile.key))
    for (const key of Object.keys(cache)) {
      // do not remove unloaded tiles or tiles in the progress of loading
      if (cache[key] === 'loading' || cache[key] === undefined) {
        continue
      }

      // do not remove visible tiles
      if (visibleKeys.has(key)) {
        continue
      }

      const value = cache[key]
      if (value instanceof HTMLImageElement) {
        URL.revokeObjectURL(value.src)
        value.remove()
      }
      delete cache[key]
    }
  }

  /**
   * Resolve tile request entries by dispatching a backend request
   *
   * A single request to get a list of tile urls is dispatched.
   *
   * Each tile request entry is then matched with a specific url for that tile
   * from the response and the associated entry promise is resolved.
   */
  const resolveTileRequests = async (view: TiledView, tiles: TileRequestEntry[]): Promise<void> => {
    if (tiles.length === 0) {
      return
    }

    let response: { [k in string]: { url: string; slotName: string }[] }
    try {
      response = await view.tilesManager.getTiles(tiles.map(({ x, y, z }) => ({ x, y, z })))
    } catch (error) {
      for (const { reject } of tiles) {
        reject(error)
      }
      return
    }

    for (const { x, y, z, resolve } of tiles) {
      const key = imageKey(x, y, z)
      resolve(Array.from(response[key]))
    }
  }

  /** Returns list of request entries using passed tiles */
  const processTiles = (
    tiles: Tile[],
    image: Partial<LoadedImageWithTiles>,
    requestRepaint: RepaintCallback,
    imageFilters: PartialRecord<string, ImageManipulationFilter>,
  ): TileRequestEntry[] => {
    const tileRequests: TileRequestEntry[] = []

    tiles.forEach((t) => {
      const entry = createTileRequestEntry(image.cache || {}, t)
      if (entry) {
        scheduleTileLoad(image.cache || {}, entry, imageFilters, requestRepaint)
        tileRequests.push(entry)
      }
    })

    return tileRequests
  }

  /**
   * As users perform a pan/zoom action we reduce the number of
   * resolved tile requests throttling resolveTileRequests()
   */
  const throttledResolveTileRequests = throttle(
    ({ view }) => {
      const lowestZoomLevel: number = Math.min(...Object.keys(tileRequestEntriesMap).map(Number))
      const visibleTiles = tileRequestEntriesMap[lowestZoomLevel].filter((t) => t.priority === 1)
      const neighbourTiles = tileRequestEntriesMap[lowestZoomLevel].filter((t) => t.priority === 2)
      if (visibleTiles.length) {
        resolveTileRequests(view, visibleTiles)
      }
      if (neighbourTiles.length) {
        resolveTileRequests(view, neighbourTiles)
      }
      delete tileRequestEntriesMap[lowestZoomLevel]
    },
    600,
    { leading: true, trailing: true },
  )

  const reloadVisibleTiles = async (
    urlsMap: { [k: string]: { url: string; slotName: string }[] },
    image: Partial<LoadedImageWithTiles>,
    imageFilters: PartialRecord<string, ImageManipulationFilter>,
    requestRepaint: RepaintCallback,
  ): Promise<void> => {
    for (const [key, urlsData] of Object.entries(urlsMap)) {
      const blendedImage = await renderTileFromUrls(urlsData, imageFilters)
      if (!image.cache) {
        image.cache = {}
      }
      image.cache[key] = blendedImage
    }
    requestRepaint()
  }

  /**
   * Start retrieval visible tiles for an image within the current camera context
   *
   * As tiles are retrieved, automatic repaint is triggered
   *
   * @param throttled, we use that boolean to distinguish between platform-made
   * actions (eg: getting visible times as the /workview page loads the first time)
   * and user made-actions (eg: pan or zooming)
   */
  const getVisibleTiles = (
    image: Partial<LoadedImageWithTiles>,
    view: TiledView,
    loadNeighbourTiles = false,
    throttled = true,
    requestRepaint: RepaintCallback,
  ): Tile[] => {
    if (image.cache === undefined) {
      image.cache = {}
    }

    const scale = view.camera.scale
    // the highest zooming out level
    const maxLevel = getMaxImageZoomLevel(image.levels)

    // Each zoom level halves the amount of scale they cover
    // For example level: 0 (1px = 1px), is between 100% - 50%
    //             level: 1 (2px = 1px)  is between  50% - 25%
    const currentZoomLevel = clamp(Math.round(-Math.log(scale) / Math.log(2)), 0, maxLevel)

    const visibleTiles: Tile[] = []

    if (currentZoomLevel < maxLevel) {
      for (let level = maxLevel; level > currentZoomLevel; level--) {
        // keep old tiles at higher zoom in memory for smooth zoom in / zoom out
        // always load the most zoomed out image
        const tiles = getTilesForZoomLevel(
          image.levels || {},
          image.cache,
          view.camera,
          level,
          loadNeighbourTiles,
        )
        if (maxLevel === level) {
          tileRequestEntriesMap[currentZoomLevel] = [
            ...(tileRequestEntriesMap[currentZoomLevel] || []),
            ...processTiles(tiles, image, requestRepaint, view.imageFilters),
          ]
        }
        visibleTiles.push(...tiles)
      }
    }

    const tiles = getTilesForZoomLevel(
      image.levels || {},
      image.cache,
      view.camera,
      currentZoomLevel,
      loadNeighbourTiles,
    )
    tileRequestEntriesMap[currentZoomLevel] = [
      ...(tileRequestEntriesMap[currentZoomLevel] || []),
      ...processTiles(tiles, image, requestRepaint, view.imageFilters),
    ]
    visibleTiles.push(...tiles)

    cleanUpTileCache(image.cache, visibleTiles)
    if (throttled) {
      throttledResolveTileRequests({
        image,
        view,
        visibleTiles,
        loadNeighbourTiles,
      })
    } else {
      resolveTileRequests(view, tileRequestEntriesMap[currentZoomLevel])
    }

    return visibleTiles
  }

  return {
    getVisibleTiles,
    reloadVisibleTiles,
  }
}
