import { markRaw } from 'vue'

import { MedicalVolumePlane } from '@/modules/Editor/MedicalMetadata'
import { MedicalModality } from '@/modules/Editor/MedicalModality'
import type { Editor } from '@/modules/Editor/editor'
import type { VtkDataManager } from '@/modules/Editor/managers/vtkDataManager'
import { Object2D } from '@/modules/Editor/models/layers/object2D'
import { ReferenceLinesLayer } from '@/modules/Editor/models/layers/referenceLinesLayer'
import { VtkLayer } from '@/modules/Editor/models/layers/vtkLayer'
import { FrameManagerEvents } from '@/modules/Editor/eventBus'
import type { ViewEvent } from '@/modules/Editor/eventBus'
import { getPrimaryViewFromView } from '@/modules/Editor/plugins/mask/utils/shared/getPrimaryViewFromView'
import type { Plane } from '@/modules/Editor/utils/raster/Plane'
import { renderDicomImageOnCanvas } from '@/modules/Editor/renderDicomImageOnCanvas'
import { renderMeasureRegion } from '@/modules/Editor/renderMeasureRegion'
import { isDicomView } from '@/modules/Editor/utils/radiology/isDicomView'
import { isRadiologicalVolumeView } from '@/modules/Editor/utils/isRadiologicalView'
import { doesNeedIsotropicTransformation } from '@/modules/Editor/utils/radiology/doesNeedIsotropicTransformation'
import { VideoView } from '@/modules/Editor/views/videoView'
import type { FramesLoaderConfig } from '@/modules/Editor/workers/FramesLoaderWorker/types'
// eslint-disable-next-line boundaries/element-types
import type { V2DatasetItemPayload } from '@/store/types/V2DatasetItemPayload'
// eslint-disable-next-line boundaries/element-types
import type { V2DatasetItemSlot } from '@/store/types/V2DatasetItemSlot'
import { isFileCompatibleWithVtk } from '@/modules/Editor/utils/radiology/isFileCompatibleWithVtk'

import { ViewTypes } from './viewTypes'
import { ViewEvents } from '@/modules/Editor/eventBus'

const shouldUseVolumeLoading = (file: V2DatasetItemSlot): boolean => {
  const modality = file.metadata?.modality

  // If the modality is a volume, use volume loading.
  return (
    modality === MedicalModality.CT ||
    modality === MedicalModality.MR ||
    modality === MedicalModality.nifti
  )
}

const getPrimaryPlaneFromFile = (file: V2DatasetItemSlot): MedicalVolumePlane =>
  file.metadata?.primary_plane ?? MedicalVolumePlane.AXIAL

const getMedicalPlaneFromFile = (file: V2DatasetItemSlot): MedicalVolumePlane | undefined => {
  const medicalMetadata = file.metadata?.medical
  if (medicalMetadata === undefined) {
    return undefined
  }
  const slotName = file.slot_name
  const planeMap = medicalMetadata.plane_map
  const planeForSlot = planeMap[slotName]
  return planeForSlot
}

const isItemMpr = (item: V2DatasetItemPayload): boolean => {
  const { slots } = item
  const slotNames = slots.map((slot) => slot.slot_name)

  const isMPR =
    slotNames.length === 3 &&
    (slotNames.includes('0') || slotNames.includes('0.1')) &&
    slotNames.includes('0.2') &&
    slotNames.includes('0.3')

  return isMPR
}

/**
 * Determines if the view is a primary view of the series, or is an MPR reformat.
 *
 */
const isPrimary = (file: V2DatasetItemSlot, item: V2DatasetItemPayload): boolean => {
  const medicalPlane = getMedicalPlaneFromFile(file)
  if (medicalPlane === undefined || !isItemMpr(item)) {
    // If no plane information or if we aren't using MPR, default to primary.
    return true
  }

  // Note in the future, in RadView, we will do these checks via SeriesInstanceUIDs.
  return medicalPlane === getPrimaryPlaneFromFile(file)
}

const isVolume = (file: V2DatasetItemSlot): boolean => {
  const medicalMetadata = file.metadata?.medical

  if (medicalMetadata === undefined) {
    // Legacy payload, is not volume
    return false
  }

  return medicalMetadata.is_volume
}

export const getMedicalVolumePlane = (file: V2DatasetItemSlot): MedicalVolumePlane =>
  file.metadata?.medical?.plane_map?.[file.slot_name] ?? MedicalVolumePlane.AXIAL

const getFrameLoaderConfig = (
  file: V2DatasetItemSlot,
  defaultConfig: FramesLoaderConfig,
): FramesLoaderConfig => ({
  ...defaultConfig,
  /**
   * For Radiology, don't prefetch low quality frames, as this destroys CINE functionality.
   * It is better to pause and show the true frame whilst buffering, than display low quality
   * frames during CINE.
   */
  hqOnly: true,
  dicomVolumePrefetching: shouldUseVolumeLoading(file),
})

export class DicomView extends VideoView {
  public readonly type = ViewTypes.DICOM
  public readonly isVolume: boolean
  public readonly isPrimary: boolean
  private readonly usingDicomVolumePrefetching: boolean
  private loadedFrames = new Set<number>()
  public areAllFramesLoaded: boolean = false

  public readonly isRadiologicalVolumeView: boolean

  // Only the primary view has a vtk data manager, if it uses vtk
  public _vtkDataManager?: VtkDataManager
  private dataManagerPromise?: Promise<VtkDataManager>

  public referenceLinesLayer?: ReferenceLinesLayer

  private readonly isCreationWithDefaultFrame: boolean = false

  constructor(
    editor: Editor,
    file: V2DatasetItemSlot,
    item: V2DatasetItemPayload,
    initialFrameIndex: number = -1,
    framesLoaderConfig: FramesLoaderConfig = {},
  ) {
    const frameLoaderConfig = getFrameLoaderConfig(file, framesLoaderConfig)

    super(editor, file, item, initialFrameIndex, frameLoaderConfig)

    // TODO: [DAR-2729] original condition was "> -1"
    // This is a temporary fix to avoid breaking the creation of DICOM views.
    this.isCreationWithDefaultFrame = initialFrameIndex > 0

    this.isPrimary = isPrimary(file, item)
    this.isVolume = isVolume(file)

    this.isRadiologicalVolumeView = item.layout
      ? isRadiologicalVolumeView({
          slots: item.slots,
          slotTypes: item.slot_types,
          layout: item.layout,
        })
      : false

    const { featureFlags } = editor
    this._mainLayerVtk = !featureFlags.MED_LIGHT_MODE && isFileCompatibleWithVtk(file.metadata)

    if (this.isMainLayerVtk) {
      const medicalVolumePlane = getMedicalVolumePlane(file)

      // Replace mainLayer with a custom VtkLayer
      this.mainLayer = markRaw(new VtkLayer(this, medicalVolumePlane))

      // The primary view owns the vtkDataManager
      // All views can access it using the getter vtkDataManager
      if (this.isPrimary) {
        // Dynamic loading of modules using vtk.js
        this.dataManagerPromise = import('@/modules/Editor/managers/vtkDataManager').then(
          async ({ VtkDataManager }) => {
            const primaryView = getPrimaryViewFromView(this)
            if (!primaryView) {
              throw new Error('Primary view not found')
            }

            const dataManager = new VtkDataManager(this.fileManager, primaryView)
            this._vtkDataManager = dataManager

            if (!featureFlags.OBLIQUE_PLANES || !this.isRadiologicalVolumeView) {
              dataManager.disableReferenceLines()
            }

            await dataManager.fetchAllSourceFrames()

            ViewEvents.vtkDataManagerChanged.emit(this.viewEvent, dataManager)
            return dataManager
          },
        )
      } else {
        // Vtk views don't need to load frames
        this.fileManager.cleanup()
      }
    }

    if (!featureFlags.OBLIQUE_PLANES) {
      // Add reference lines to reference lines view
      this.referenceLinesLayer = new ReferenceLinesLayer(this)

      const onBeforeRender = (canvas: HTMLCanvasElement): void => {
        canvas.width = this.camera.width
        canvas.height = this.camera.height
      }

      this.onCleanup.push(
        this.referenceLinesLayer.onBeforeRender((ctx, canvas) => onBeforeRender(canvas)).release,
      )
    }

    this.usingDicomVolumePrefetching = !!frameLoaderConfig.dicomVolumePrefetching

    const onFrameLoaded = (e: ViewEvent, indexes: number[]): void => {
      if (e.viewId === this.id) {
        indexes.forEach((i) => this.loadedFrames.add(i))

        if (this.loadedFrames.size === this.totalFrames) {
          this.areAllFramesLoaded = true
        }
      }
    }

    FrameManagerEvents.framesReady.on(onFrameLoaded)

    this.onCleanup.push(() => {
      FrameManagerEvents.framesReady.off(onFrameLoaded)
    })
  }

  get vtkDataManager(): VtkDataManager | undefined {
    if (this._vtkDataManager) {
      return this._vtkDataManager
    }
    const primaryView = getPrimaryViewFromView(this)
    if (!primaryView || !isDicomView(primaryView)) {
      return
    }
    return primaryView._vtkDataManager
  }

  get layers(): VideoView['layers'] {
    const layers = super.layers

    if (this.referenceLinesLayer !== undefined) {
      layers.push(this.referenceLinesLayer)
    }

    return layers
  }

  async init(): Promise<void> {
    if (!this.mainLayer.canvas) {
      return
    }

    if (
      !this.isMainLayerVtk &&
      this.fileManager.metadata &&
      doesNeedIsotropicTransformation(this.fileManager.metadata)
    ) {
      this.camera.setImage({
        width: this.fileManager.metadata.width,
        height: this.fileManager.metadata.height,
      })
      this.camera.lockImage()
    }

    this.initState()
    this.camera.setConfig({ centerY: true })

    const radViewEligible = this.editor.slotTypes.length > 1

    if (radViewEligible) {
      this.mainLayer.canvas.style.borderRadius = '4px'
    }

    // For DICOM we use dark background
    this.mainLayer.canvas.style.background = '#000'
    this.mainLayer.add(
      new Object2D(ViewTypes.DICOM, () => {
        if (!this.currentFrame) {
          return
        }
        if (!this.mainLayer.canvas) {
          return
        }

        renderDicomImageOnCanvas(
          this,
          this.mainLayer.canvas,
          this.currentFrame,
          this.fileManager.metadata || null,
        )

        if (this.editor.renderMeasures) {
          renderMeasureRegion(this)
        }
      }),
    )

    // Jump to the middle frame for volumetric views only
    // Otherwise we should start on frame 0, like video does.
    if (this.isRadiologicalVolumeView) {
      await this.focusCenterFrame()
    } else {
      await this.jumpToFrame(this.currentFrameIndex)
    }

    if (this.isMainLayerVtk && this.isPrimary) {
      // Fetch the current frame immediately, it's needed for:
      // - vtk.js to be able to render something on the screen quickly
      // - SAM so the model can be started properly
      this.currentFrame = await this.fileManager.getHQFrame(this.currentFrameIndex)
      await this.vtkDataManager?.fetchSourceFrame(this.currentFrameIndex)
    }

    this.referenceLinesLayer?.init()

    this.resetZoom()
    this.scaleToFit()
  }

  /**
   * We use an overriden play method, as for DicomView we don't
   * want to use low quality frames. We should just try and play at the native framerate,
   * And otherwise await and just play slower if our connection is not good enough.
   *
   * Once all images are cached, we should be able to play at the desired framerate.
   */
  play(): void {
    if (this.isPlaying) {
      return
    }
    this.isPlaying = true
    const length = this.framesIndexes.length

    this.videoInterval = window.setInterval(async () => {
      if (this.isLoading) {
        return
      }

      const nextFrameIndex = (this.currentFrameIndex + 1) % length
      await this.jumpToHQFrame(nextFrameIndex)
    }, 1000 / this.fileManager.fps)
  }

  /**
   * Method that jumps to HQ frames only, will never load/display LQ frames.
   */
  private jumpToHQFrame = async (frameIndex: number): Promise<void> => {
    if (frameIndex < 0 || frameIndex > this.lastFrameIndex) {
      return
    }

    this.currentFrameIndex = frameIndex

    if (this.fileManager.isHQFrameLoaded(frameIndex)) {
      this.loading = false

      if (this.usingDicomVolumePrefetching) {
        // Set origin point for volume fetching
        await this.fileManager.loadFramesFrom(frameIndex)
      }

      this.currentFrame = await this.fileManager.getHQFrame(this.currentFrameIndex)

      return
    }

    if (!this.fileManager.isHQFrameLoaded(frameIndex)) {
      this.loading = true
    }

    this.fileManager
      .getHQFrame(frameIndex)
      .then((hqFrame) => {
        if (this.currentFrameIndex === frameIndex) {
          this.currentFrame = hqFrame
          if (this.usingDicomVolumePrefetching) {
            // Set origin point for volume fetching
            this.fileManager.loadFramesFrom(frameIndex)
          }
          if (hqFrame) {
            this.camera.setImage(hqFrame.data)
          }

          // jumping between frames changes subAnnotation content so the redraw option is enabled
          this.annotationManager.invalidateAnnotationCache()
          this.commentManager.deselectItem()
        }
        this.loading = false
      })
      .catch(() => {
        this.loading = false
      })
  }

  /**
   * Used by tools that jump straight to a region of interest.
   * Doesn't trigger loading of video frames forwards in time as videoView does,
   * to reduce the amount of forward fetching we are doing.
   *
   * @param frameIndex The frame index in the stack to jump to.
   */
  async jumpToFrameNoLoad(frameIndex: number): Promise<void> {
    if (frameIndex < 0 || frameIndex > this.lastFrameIndex) {
      return
    }

    this.currentFrameIndex = frameIndex
    this.loading = false
    this.currentFrame = await this.fileManager.getHQFrame(this.currentFrameIndex)
  }

  public toggleReferenceLines(): void {
    this.referenceLinesLayer?.toggleReferenceLines()
    this.vtkDataManager?.toggleReferenceLines()
  }

  public getVoxelValueAt(x: number, y: number, z: number, plane: Plane | undefined): number {
    const DEFAULT_VOXEL_VALUE = Number.MIN_SAFE_INTEGER
    return this.vtkDataManager?.getVoxelValueAt(x, y, z, plane) || DEFAULT_VOXEL_VALUE
  }

  /**
   * Focuses the view on the central frame of the DICOM when it loads.
   */
  private async focusCenterFrame(): Promise<void> {
    if (this.isCreationWithDefaultFrame) {
      await this.jumpToFrame(this.currentFrameIndex)
      return
    }
    const { totalFrames } = this
    const middleFrameIndex = Math.floor(totalFrames / 2)

    await this.jumpToFrame(middleFrameIndex)
  }

  override destroy(): void {
    this.dataManagerPromise?.then((dataManager) => dataManager.delete())
    super.destroy()
  }
}
