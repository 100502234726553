import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import { createAnnotationFromDeserializable } from '@/modules/Editor/models/annotation/annotationFactories'
import type { AnnotationMetadata } from '@/backend/darwin/loadPaginatedAnnotationMetadata'
import { watch, shallowRef, computed, triggerRef, type ComputedRef } from 'vue'
import { ACTIONS } from './actions'
import AnnotationPackageWorker from './annotationPackageWorker?worker'
import { useReactiveMapLinkedList } from '@/pinia/utils/useReactiveMapLinkedList'
import { toWorker } from '@/modules/Editor/utils/workers/toWorker'
import { getMainAnnotationType, MainAnnotationType } from '@/core/annotationTypes'
import type { PartialRecord } from '@/core/helperTypes'
import type {
  MetadataIndicesFields,
  PaginatedAnnotationPayload,
  V2AnnotationPayload,
} from '@/store/types/StageAnnotationPayload'
import { useClassesById } from '@/modules/Classes/useClassesById'
import { useCurrentItemStore } from '@/modules/Workview/useCurrentItemStore'
import { isAnnotationOutOfView } from '@/modules/Editor/utils/outOfViewUtils'
import { isVideoAnnotationDataPayload } from '@/modules/Editor/models/annotation/annotationKindValidator'
import type {
  AnnotationActionOptions,
  AnnotationsFramePackage,
  ParsedFramePayload,
  PrecalculatedStoreType,
} from './AnnotationsFramePackage'
import { useWorkviewSettingsStore } from '@/pinia/useWorkviewSettingsStore'
import { type initDataParsing } from './dataCalculation'
import { removeAnnotationFromPackage } from './removeAnnotationFromPackage'
import { addAnnotationForPackage } from './addAnnotationForPackage'
import { updateAnnotationForPackage } from './updateAnnotationForPackage'
import { useFeatureFlagsStore } from '@/pinia/useFeatureFlagsStore'
import type { FramesRange } from '@/uiKitLegacy/FramesRange'
import type { SequenceData } from '@/core/annotations'
import { defineComposable } from '@/core/utils/defineComposable'

export const useAnnotationPackageManager = defineComposable(() => {
  let annotationPackageWorker: AnnotationPackageWorker | null = null
  let toWorkerInstance: ReturnType<typeof toWorker> | null = null

  const currentItemStore = useCurrentItemStore()
  const workviewSettings = useWorkviewSettingsStore()
  const featuresStore = useFeatureFlagsStore()

  const framesNeedsLoading = shallowRef(new Set<number>())

  const { classesById, editorClassesById } = useClassesById()

  let firstAnnotationsPageSet = false

  const parsedFrames = shallowRef(new Set<number>())

  const precalculatedStore: PrecalculatedStoreType = {
    renderableData: new Map(),
  }

  /**
   * Keeps the map of the tag annotations
   */
  const tagsMap = useReactiveMapLinkedList<V2AnnotationPayload & MetadataIndicesFields>()
  const allTags = tagsMap.values
  /**
   * Keeps the sorted map of the non tag annotations
   *
   * We use this data structure to provide the fast re-order of the annotations
   */
  const annotationsMap = useReactiveMapLinkedList<V2AnnotationPayload & MetadataIndicesFields>()
  const editorAnnotationsMap = new Map<string, Annotation>()
  const allAnnotations = annotationsMap.values
  /**
   * Keeps the masks annotations map
   */
  const masksMap = useReactiveMapLinkedList<V2AnnotationPayload>()
  const allMasks = masksMap.values
  /**
   * Keeps the single raster layer annotation
   */
  const rasterLayersMap = useReactiveMapLinkedList<V2AnnotationPayload>()
  const allRasterLayers = rasterLayersMap.values

  const annotationsEntries = computed(() => [
    ...allAnnotations.value,
    ...allTags.value,
    ...allMasks.value,
    ...allRasterLayers.value,
  ])
  const annotationsPerSlot = shallowRef<Map<string, Map<number, AnnotationsFramePackage>>>(
    new Map(),
  )
  /**
   * The current frame annotation package per slot.
   * NOTE: For Image item it will return annotations per slot using frame index 0.
   */
  const currentFrameAnnotationsFrameDataPerSlot = computed(() => {
    const res: PartialRecord<string, AnnotationsFramePackage | null> = {}

    if (!featuresStore.featureFlags.ANNOTATIONS_PACKAGE) {
      return res
    }

    Object.entries(workviewSettings.multiSlotFrameIndexes).forEach(([slotName, index]) => {
      if (!slotName || index === undefined) {
        throw new Error('Invalid slot name or index')
      }

      res[slotName] = annotationsPerSlot.value?.get(slotName)?.get(index) || null
    })

    return res
  })

  const provideEditorClasses = (): void => {
    toWorkerInstance?.postMessage(ACTIONS.SET_EDITOR_CLASSES_BY_ID, {
      editorClassesById: editorClassesById.value,
    })
  }

  // Send the editor classes to the worker
  watch(
    () => editorClassesById.value,
    () => {
      provideEditorClasses()
    },
    { immediate: true },
  )

  const provideConfig = (): void => {
    const config = Object.entries(currentItemStore.multiSlotInfosMap).reduce(
      (acc, [slotName, slotInfo]) => {
        acc[slotName] = {
          totalFrames: slotInfo.framesCount,
          videoAnnotationDuration: workviewSettings.videoAnnotationDuration,
          isProcessedAsVideo: slotInfo.isProcessedAsVideo,
        }
        return acc
      },
      {} as PartialRecord<
        string,
        { totalFrames: number; videoAnnotationDuration: number; isProcessedAsVideo: boolean }
      >,
    )

    toWorkerInstance?.postMessage(ACTIONS.SET_CONFIG, {
      config,
    })
  }

  // Send the config to the worker
  watch(
    () => [currentItemStore.multiSlotInfosMap, workviewSettings.videoAnnotationDuration],
    () => {
      provideConfig()
    },
    { immediate: true },
  )

  const getAnnotation = (id: string): (V2AnnotationPayload & MetadataIndicesFields) | null =>
    annotationsMap.get(id) || tagsMap.get(id) || masksMap.get(id) || rasterLayersMap.get(id)

  const getAnnotationRef = (
    id: string,
  ): ComputedRef<(V2AnnotationPayload & MetadataIndicesFields) | null> => {
    const annRef = annotationsMap.getRef(id)
    const tagRef = tagsMap.getRef(id)
    const maskRef = masksMap.getRef(id)

    return computed(() => annRef.value || tagRef.value || maskRef.value)
  }

  /**
   * Resolve the annotation main type by class id
   */
  const getAnnotationMainTypeByClassId = (annotationClassId: number): MainAnnotationType | null => {
    const annotationClassPayload = classesById.value[annotationClassId]
    if (!annotationClassPayload) {
      return null
    }

    return getMainAnnotationType(annotationClassPayload.annotation_types) || null
  }

  /**
   * Resolve the annotation main type by id
   */
  const getAnnotationMainTypeById = (id: string): MainAnnotationType | null => {
    const annotationClassId = getAnnotation(id)?.annotation_class_id
    if (!annotationClassId) {
      throw new Error(
        'trying to access the class ID of an annotation that is not loaded within the editor',
      )
    }

    return getAnnotationMainTypeByClassId(annotationClassId)
  }

  const isRasterLayer = (classId: number): boolean =>
    getAnnotationMainTypeByClassId(classId) === MainAnnotationType.RasterLayer

  const isAnnotationMask = (classId: number): boolean =>
    getAnnotationMainTypeByClassId(classId) === MainAnnotationType.Mask

  const isAnnotationTag = (classId: number): boolean =>
    getAnnotationMainTypeByClassId(classId) === MainAnnotationType.Tag

  const requestedIdleCallbacks = new Map()

  const cancelAllRequestedIdleCallbacks = (): void => {
    for (const request of requestedIdleCallbacks.values()) {
      cancelIdleCallback(request)
    }
    requestedIdleCallbacks.clear()
  }

  const clear = (): void => {
    firstAnnotationsPageSet = false
    parsedFrames.value.clear()
    triggerRef(parsedFrames)
    cancelAllRequestedIdleCallbacks()
    annotationsMap.clear()
    tagsMap.clear()
    masksMap.clear()
    rasterLayersMap.clear()
    annotationsPerSlot.value.clear()
    triggerRef(annotationsPerSlot)

    toWorkerInstance?.cleanup()
    toWorkerInstance = null
    annotationPackageWorker?.terminate()
    annotationPackageWorker = null

    precalculatedStore.renderableData.clear()
  }

  const init = (): void => {
    clear()

    annotationPackageWorker = new AnnotationPackageWorker()
    toWorkerInstance = toWorker(annotationPackageWorker)

    provideEditorClasses()
    provideConfig()

    toWorkerInstance?.addListener<ParsedFramePayload>(
      ACTIONS.CANCEL_ALL_PARSED_FRAME_EVENTS,
      cancelAllRequestedIdleCallbacks,
    )

    toWorkerInstance?.addListener<ParsedFramePayload>(
      ACTIONS.YIELD_PARSED_FRAME_PER_SLOT,
      (parsedPayload) => {
        cancelIdleCallback(requestedIdleCallbacks.get(parsedPayload.frameIndex))
        requestedIdleCallbacks.set(
          parsedPayload.frameIndex,
          requestIdleCallback(() => {
            requestedIdleCallbacks.delete(parsedPayload.frameIndex)

            for (const id of parsedPayload.removedIds) {
              const renderableData = precalculatedStore.renderableData.get(parsedPayload.frameIndex)
              if (!renderableData) {
                continue
              }

              renderableData.itemsBBox.delete(id)
              renderableData.rTreeItems.delete(id)
              renderableData.renderableItems.delete(id)
            }

            // For all new annotations we will add them to the precalculated store
            // to keep "heavy" data (like annotation.data) of the annotations
            for (const id of parsedPayload.newParsedChank.allNewIds) {
              let renderableData = precalculatedStore.renderableData.get(parsedPayload.frameIndex)
              if (!renderableData) {
                renderableData = {
                  itemsBBox: new Map(),
                  rTreeItems: new Map(),
                  renderableItems: new Map(),
                }
                precalculatedStore.renderableData.set(parsedPayload.frameIndex, renderableData)
              }

              if (parsedPayload.newParsedChank.annotationsRenderData.itemsBBoxMap[id]) {
                renderableData.itemsBBox.set(
                  id,
                  parsedPayload.newParsedChank.annotationsRenderData.itemsBBoxMap[id],
                )
              }
              if (parsedPayload.newParsedChank.annotationsRenderData.rTreeItems[id]) {
                renderableData.rTreeItems.set(
                  id,
                  parsedPayload.newParsedChank.annotationsRenderData.rTreeItems[id],
                )
              }
              if (parsedPayload.newParsedChank.annotationsRenderData.itemsMap[id]) {
                renderableData.renderableItems.set(
                  id,
                  parsedPayload.newParsedChank.annotationsRenderData.itemsMap[id],
                )
              }
            }

            if (!annotationsPerSlot.value) {
              annotationsPerSlot.value = new Map()
            }
            for (const [
              slotName,
              framePackagePerFrame,
            ] of parsedPayload.perSlotPerFrame.entries()) {
              for (const [frameIndex, parsedFramePayload] of framePackagePerFrame.entries()) {
                if (!annotationsPerSlot.value.has(slotName)) {
                  annotationsPerSlot.value.set(slotName, new Map())
                }
                const annotationsPerFrame = annotationsPerSlot.value.get(slotName)
                if (!annotationsPerFrame) {
                  return
                }

                annotationsPerFrame.delete(frameIndex)

                const framePackage: AnnotationsFramePackage = {
                  storeAnnotations: [],
                  tagAnnotations: parsedFramePayload.tags.map((id) => {
                    const ann = tagsMap.get(id)
                    if (!ann) {
                      throw new Error('Annotation not found')
                    }
                    return ann
                  }),
                  editorAnnotations: [],
                  orderedAnnotationIds: parsedFramePayload.annotations,
                  annotationsMap: {},
                  annotationsRenderData: {
                    itemsBBoxMap: new Map(),
                    rTreeItems: [],
                    itemsMap: new Map(),
                    zIndexesList: parsedFramePayload.annotations,
                  },
                }

                parsedFramePayload.annotations.forEach((id) => {
                  const storeAnn = annotationsMap.get(id)
                  if (!storeAnn) {
                    throw new Error('Annotation not found')
                  }
                  framePackage.storeAnnotations.push(storeAnn)

                  const editorAnn = editorAnnotationsMap.get(id)
                  if (!editorAnn) {
                    throw new Error('Annotation not found')
                  }
                  framePackage.editorAnnotations.push(editorAnn)
                  framePackage.annotationsMap[id] = editorAnn

                  // TODO: DAR-3946 we should replace this solution with marking annotation as hidden for on layer
                  if (
                    isVideoAnnotationDataPayload(storeAnn.data) &&
                    storeAnn.data.hidden_areas &&
                    isAnnotationOutOfView(storeAnn.data.hidden_areas, frameIndex)
                  ) {
                    framePackage.annotationsRenderData.zIndexesList =
                      framePackage.annotationsRenderData.zIndexesList.filter(
                        (annId) => annId !== id,
                      )
                    return
                  }

                  const itemsBBox = precalculatedStore.renderableData
                    .get(frameIndex)
                    ?.itemsBBox.get(id)
                  if (itemsBBox) {
                    framePackage.annotationsRenderData.itemsBBoxMap.set(id, itemsBBox)
                  }
                  const rTreeItem = precalculatedStore.renderableData
                    .get(frameIndex)
                    ?.rTreeItems.get(id)
                  if (rTreeItem) {
                    framePackage.annotationsRenderData.rTreeItems.push(rTreeItem)
                  }
                  const item = precalculatedStore.renderableData
                    .get(frameIndex)
                    ?.renderableItems.get(id)
                  if (item) {
                    framePackage.annotationsRenderData.itemsMap.set(id, item)
                  }
                })

                annotationsPerFrame.set(frameIndex, framePackage)

                if (
                  workviewSettings.activeSlotName === slotName &&
                  workviewSettings.currentFrameIndex === frameIndex
                ) {
                  triggerRef(annotationsPerSlot)
                }
              }
            }

            toWorkerInstance?.postMessage(ACTIONS.ITEM_DELIVERED, {
              frameIndex: parsedPayload.frameIndex,
              ids: parsedPayload.newParsedChank.allNewIds,
            })

            parsedFrames.value.add(parsedPayload.frameIndex)
            triggerRef(parsedFrames)
          }),
        )
      },
    )
  }

  const pushAnnotationsToInternalStores = (annotations: V2AnnotationPayload[]): void => {
    annotations.forEach((ann) => {
      const annotationClass = editorClassesById.value[ann.annotation_class_id]
      if (!annotationClass) {
        return
      }
      const type = getMainAnnotationType(annotationClass.annotation_types)
      if (!type) {
        return
      }

      if (type === MainAnnotationType.Tag) {
        tagsMap.addFirst(ann, true)
        return
      }

      if (type === MainAnnotationType.Mask) {
        masksMap.addFirst(ann, true)
        return
      }
      if (type === MainAnnotationType.RasterLayer) {
        rasterLayersMap.addFirst(ann, true)
        return
      }

      const editorAnnotation = createAnnotationFromDeserializable(annotationClass, ann)
      if (!editorAnnotation) {
        return
      }
      editorAnnotationsMap.set(ann.id, editorAnnotation)
      annotationsMap.addFirst(ann, true)
    })

    annotationsMap.triggerRef()
    tagsMap.triggerRef()
    masksMap.triggerRef()
    rasterLayersMap.triggerRef()
  }

  const pushMetadataToInternalStores = (metadatas: AnnotationMetadata[]): void => {
    metadatas.forEach((metadata) => {
      const frames: SequenceData['frames'] = {}
      const subFrames: SequenceData['sub_frames'] = {}

      // TODO: add and it should be updated on annotation update
      // frames_indices: metadata.frames_indices,
      // sub_frames_indices: metadata.sub_frames_indices,
      // auto_track_frames_indices: metadata.auto_track_frames_indices,
      const ann = {
        id: metadata.annotation_id,
        actors: metadata.actors,
        annotation_class_id: metadata.annotation_class_id,
        annotation_group_id: metadata.annotation_group_id,
        z_index: metadata.z_index,
        context_keys: metadata.context_keys,
        properties: metadata.properties,
        data: {
          interpolated: metadata.interpolated,
          hidden_areas: metadata.hidden_areas,
          segments: metadata.segments,
          frames,
          sub_frames: subFrames,
          global_sub_types: metadata.global_sub_types,
        },
      }

      metadata.frames_indices.forEach((i) => {
        framesNeedsLoading.value.add(i)
      })
      metadata.sub_frames_indices.forEach((i) => {
        framesNeedsLoading.value.add(i)
      })

      const annotationClass = editorClassesById.value[ann.annotation_class_id]
      if (!annotationClass) {
        throw new Error('Annotation class not found')
      }
      const type = getMainAnnotationType(annotationClass.annotation_types)
      if (!type) {
        return
      }

      if (type === MainAnnotationType.Tag) {
        tagsMap.addFirst(ann, true)
        return
      }

      if (type === MainAnnotationType.Mask) {
        masksMap.addFirst(ann, true)
        return
      }
      if (type === MainAnnotationType.RasterLayer) {
        rasterLayersMap.addFirst(ann, true)
        return
      }

      annotationsMap.addFirst(ann, true)
    })

    triggerRef(framesNeedsLoading)
    annotationsMap.triggerRef()
    tagsMap.triggerRef()
    masksMap.triggerRef()
    rasterLayersMap.triggerRef()
  }

  /**
   * Provide the initial data to the worker to start the parsing
   * as a result we will get the list of masks and raster layers
   * and we will get the precalculated and parsed annotations per frame with messages from the worker
   *
   * NOTE: NON-PAGINATED VERSION
   */
  const initAnnotations = async (
    range: FramesRange,
    annotations: V2AnnotationPayload[],
  ): Promise<void> => {
    // Trigger the initial data parsing
    // NOTE: we will apply the annotations gradually with messages from the worker
    // using ACTIONS.YIELD_PARSED_FRAME_PER_SLOT type
    await toWorkerInstance?.postMessage<ReturnType<typeof initDataParsing>>(
      ACTIONS.INIT_DATA_PARSING,
      {
        range,
        annotations,
      },
    )

    pushAnnotationsToInternalStores(annotations)
  }

  /**
   * NOTE: PAGINATED VERSION
   */
  const pushMetadata = (range: FramesRange, metadata: AnnotationMetadata[]): void => {
    pushMetadataToInternalStores(metadata)
  }

  const pushFramesData = async (
    range: FramesRange,
    framesDatas: PaginatedAnnotationPayload[],
  ): Promise<void> => {
    const annotationsToPush: V2AnnotationPayload[] = []

    framesDatas.forEach((frameData) => {
      const ann = getAnnotation(frameData.annotation_id)
      if (!ann) {
        throw new Error('Annotation not found')
      }

      Object.keys(frameData.frames).forEach((frameIndex) => {
        if (!isVideoAnnotationDataPayload(ann.data)) {
          return
        }

        framesNeedsLoading.value.delete(+frameIndex)
        ann.data.frames[frameIndex] = frameData.frames[frameIndex]
      })

      Object.keys(frameData.sub_frames).forEach((frameIndex) => {
        if (!isVideoAnnotationDataPayload(ann.data)) {
          return
        }

        framesNeedsLoading.value.delete(+frameIndex)
        ann.data.sub_frames[frameIndex] = frameData.sub_frames[frameIndex]
      })

      annotationsToPush.push(ann)

      const annotationClass = editorClassesById.value[ann.annotation_class_id]
      if (!annotationClass) {
        throw new Error('Annotation class not found')
      }

      const editorAnnotation = createAnnotationFromDeserializable(annotationClass, ann)
      if (!editorAnnotation) {
        return
      }

      editorAnnotationsMap.set(ann.id, editorAnnotation)
    })

    triggerRef(framesNeedsLoading)

    if (!firstAnnotationsPageSet) {
      firstAnnotationsPageSet = true

      await toWorkerInstance?.postMessage<ReturnType<typeof initDataParsing>>(
        ACTIONS.INIT_DATA_PARSING,
        {
          range,
          annotations: annotationsToPush,
        },
      )
      return
    }

    await toWorkerInstance?.postMessage<ReturnType<typeof initDataParsing>>(
      // TODO: push frame with priority should set priority to the worker
      ACTIONS.INIT_DATA_PARSING, // TODO: FIrst page should init data parsiong, next page should push
      {
        range,
        annotations: annotationsToPush,
      },
    )
  }

  /**
   * Remove the frames data keeping the given range in the memory and mark the frames as needing loading.
   * We use an array to keep ranges to support a multi-slot layout.
   */
  const flushFramesData = (rangesToKeep: FramesRange[]): void => {
    for (const i of parsedFrames.value.values()) {
      const rangeToKeep = rangesToKeep.find((range) => range.from <= i && range.to >= i)
      if (rangeToKeep) {
        continue
      }

      parsedFrames.value.delete(i)
      framesNeedsLoading.value.add(i)

      workviewSettings.slotNames.forEach((slotName) => {
        const annPackage = annotationsPerSlot.value?.get(slotName)?.get(i)
        annPackage?.storeAnnotations.forEach((ann) => {
          if (!isVideoAnnotationDataPayload(ann.data)) {
            return
          }

          delete ann.data.frames[i]
          delete ann.data.sub_frames[i]
        })

        annotationsPerSlot.value?.get(slotName)?.delete(i)
      })
    }

    triggerRef(framesNeedsLoading)
    triggerRef(parsedFrames)
    annotationsMap.triggerRef()
  }

  const getPrevAnnotation = (id: string): string | null => {
    const annIndex =
      currentFrameAnnotationsFrameDataPerSlot.value[
        workviewSettings.activeSlotName
      ]?.orderedAnnotationIds.indexOf(id)

    if (annIndex === undefined || annIndex === -1) {
      return null
    }

    return (
      currentFrameAnnotationsFrameDataPerSlot.value[workviewSettings.activeSlotName]
        ?.orderedAnnotationIds[annIndex - 1] || null
    )
  }
  const _updateAnnotationCurrentFrame = (updatedAnnotation: V2AnnotationPayload): void => {
    const annotationClass = editorClassesById.value[updatedAnnotation.annotation_class_id]
    if (!annotationClass) {
      throw new Error('Annotation class not found')
    }

    const type = getMainAnnotationType(annotationClass.annotation_types)
    if (!type) {
      throw new Error('Annotation type not found')
    }

    const currentAnnotation = getAnnotation(updatedAnnotation.id)
    if (!currentAnnotation) {
      throw new Error('Annotation not found')
    }

    if (type === MainAnnotationType.Tag) {
      tagsMap.set(updatedAnnotation.id, updatedAnnotation)
    } else if (type === MainAnnotationType.Mask) {
      masksMap.set(updatedAnnotation.id, updatedAnnotation)
      return
    } else {
      annotationsMap.set(updatedAnnotation.id, updatedAnnotation)
      const annotationClass = editorClassesById.value[updatedAnnotation.annotation_class_id]
      if (!annotationClass) {
        throw new Error('Annotation class not found')
      }
      const editorAnnotation = createAnnotationFromDeserializable(
        annotationClass,
        updatedAnnotation,
      )
      if (!editorAnnotation) {
        throw new Error('Editor annotation not found')
      }
      editorAnnotationsMap.set(updatedAnnotation.id, editorAnnotation)
    }

    const currentFrameAnnotationsPackage =
      currentFrameAnnotationsFrameDataPerSlot.value[workviewSettings.activeSlotName]

    if (!currentFrameAnnotationsPackage) {
      return
    }

    if (!currentItemStore.activeSlot) {
      throw new Error('Active slot is not set')
    }

    // Will add new annotaiton to the currentFrameAnnotationsPackage
    updateAnnotationForPackage(
      currentAnnotation,
      precalculatedStore,
      currentFrameAnnotationsPackage,
      updatedAnnotation,
      editorAnnotationsMap.get(updatedAnnotation.id),
      workviewSettings.activeSlotName,
      workviewSettings.currentFrameIndex,
      editorClassesById.value,
      {
        totalFrames: currentItemStore.activeSlot.framesCount,
        videoAnnotationDuration: workviewSettings.videoAnnotationDuration,
        isProcessedAsVideo: currentItemStore.activeSlot.isProcessedAsVideo,
      },
    )

    triggerRef(annotationsPerSlot)

    if (type === MainAnnotationType.Tag) {
      return
    }

    toWorkerInstance?.postMessage(ACTIONS.ITEM_DELIVERED, {
      frameIndex: workviewSettings.currentFrameIndex,
      ids: new Set([updatedAnnotation.id]),
    })
  }
  const updateAnnotation = (updatedAnnotation: V2AnnotationPayload): void => {
    _updateAnnotationCurrentFrame(updatedAnnotation)

    const type = getAnnotationMainTypeById(updatedAnnotation.id)

    if (type === MainAnnotationType.Mask) {
      return
    }

    const prevAnn = getPrevAnnotation(updatedAnnotation.id)
    const options: AnnotationActionOptions = {
      position: {
        reference: prevAnn ? { id: prevAnn } : null,
        direction: 'down',
      },
    }

    // Trigger add annotation to the pre-calculated annotations packages
    toWorkerInstance?.postMessage(ACTIONS.UPDATE_ANNOTATION, {
      annotation: updatedAnnotation,
      options,
    })
  }

  const _createAnnotationCurrentFrame = (newAnnotation: V2AnnotationPayload): void => {
    const annotationClass = editorClassesById.value[newAnnotation.annotation_class_id]
    if (!annotationClass) {
      throw new Error('Annotation class not found')
    }

    const type = getMainAnnotationType(annotationClass.annotation_types)
    if (!type) {
      throw new Error('Annotation type not found')
    }

    if (!annotationsPerSlot.value.has(workviewSettings.activeSlotName)) {
      annotationsPerSlot.value.set(workviewSettings.activeSlotName, new Map())
    }
    const annotationsPerFrame = annotationsPerSlot.value.get(workviewSettings.activeSlotName)

    if (!annotationsPerFrame) {
      throw new Error('Annotations per frame is not set')
    }

    if (!annotationsPerFrame.has(workviewSettings.currentFrameIndex)) {
      annotationsPerFrame.set(workviewSettings.currentFrameIndex, {
        storeAnnotations: [],
        editorAnnotations: [],
        annotationsMap: {},
        tagAnnotations: [],
        orderedAnnotationIds: [],
        annotationsRenderData: {
          // This loops can be optimized
          itemsBBoxMap: new Map(),
          rTreeItems: [],
          itemsMap: new Map(),
          zIndexesList: [],
        },
      })
    }

    const currentFrameAnnotationsPackage = annotationsPerFrame.get(
      workviewSettings.currentFrameIndex,
    )

    if (!currentFrameAnnotationsPackage) {
      throw new Error('Current frame annotations package is not set')
    }

    if (!currentItemStore.activeSlot) {
      throw new Error('Active slot is not set')
    }

    if (type === MainAnnotationType.Mask) {
      masksMap.addFirst(newAnnotation)
      return
    }

    if (type === MainAnnotationType.Tag) {
      tagsMap.addFirst(newAnnotation)
    } else {
      annotationsMap.addFirst(newAnnotation)
      const editorAnnotation = createAnnotationFromDeserializable(annotationClass, newAnnotation)
      if (!editorAnnotation) {
        throw new Error('Editor annotation not found')
      }
      editorAnnotationsMap.set(newAnnotation.id, editorAnnotation)
    }

    // Will add new annotaiton to the currentFrameAnnotationsPackage
    addAnnotationForPackage(
      precalculatedStore,
      currentFrameAnnotationsPackage,
      newAnnotation,
      editorAnnotationsMap.get(newAnnotation.id),
      workviewSettings.activeSlotName,
      workviewSettings.currentFrameIndex,
      editorClassesById.value,
      {
        totalFrames: currentItemStore.activeSlot.framesCount,
        videoAnnotationDuration: workviewSettings.videoAnnotationDuration,
        isProcessedAsVideo: currentItemStore.activeSlot.isProcessedAsVideo,
      },
    )

    triggerRef(annotationsPerSlot)

    toWorkerInstance?.postMessage(ACTIONS.ITEM_DELIVERED, {
      frameIndex: workviewSettings.currentFrameIndex,
      ids: new Set([newAnnotation.id]),
    })
  }
  const createAnnotation = (newAnnotation: V2AnnotationPayload): void => {
    _createAnnotationCurrentFrame(newAnnotation)

    const type = getAnnotationMainTypeById(newAnnotation.id)

    if (type === MainAnnotationType.Mask) {
      return
    }

    // Trigger add annotation to the pre-calculated annotations packages
    toWorkerInstance?.postMessage(ACTIONS.ADD_ANNOTATION, {
      annotation: newAnnotation,
    })
  }

  const _removeAnnotationCurrentFrame = (annId: string): void => {
    const type = getAnnotationMainTypeById(annId)

    if (type === MainAnnotationType.Mask) {
      masksMap.remove(annId)
      return
    }

    const currentFrameAnnotationsPackage =
      currentFrameAnnotationsFrameDataPerSlot.value[workviewSettings.activeSlotName]

    if (!currentFrameAnnotationsPackage) {
      return
    }

    if (!currentItemStore.activeSlot) {
      throw new Error('Active slot is not set')
    }

    const annotation = getAnnotation(annId)
    if (!annotation) {
      return
    }

    // Remove annotation from the calculated current frame
    if (currentFrameAnnotationsPackage) {
      removeAnnotationFromPackage(
        precalculatedStore,
        currentFrameAnnotationsPackage,
        annotation,
        currentItemStore.activeSlot.framesCount,
      )
      triggerRef(annotationsPerSlot)
    }

    if (type === MainAnnotationType.Tag) {
      tagsMap.remove(annId)
      return
    }

    editorAnnotationsMap.delete(annId)
    annotationsMap.remove(annId)
  }
  const removeAnnotation = (annId: string): void => {
    const type = getAnnotationMainTypeById(annId)

    _removeAnnotationCurrentFrame(annId)

    if (type === MainAnnotationType.Mask) {
      return
    }

    // Trigger remove annotation from the pre-calculated annotations packages
    toWorkerInstance?.postMessage(ACTIONS.REMOVE_ANNOTATION, {
      annotationId: annId,
    })
  }

  const reorderAnnotation = (
    toReorder: { id: string },
    reference: { id: string } | null,
    /** needed to determine if `toReorder`
     * will be placed before or after `reference`*/
    direction: 'up' | 'down',
  ): void => {
    const ann = annotationsMap.get(toReorder.id)
    if (!ann || isAnnotationTag(ann.annotation_class_id)) {
      return
    }

    if (
      isAnnotationTag(ann.annotation_class_id) ||
      isAnnotationMask(ann.annotation_class_id) ||
      isRasterLayer(ann.annotation_class_id)
    ) {
      return
    }

    annotationsMap.remove(toReorder.id)
    if (direction === 'down') {
      if (reference === null) {
        annotationsMap.addLast(ann)
      } else {
        annotationsMap.addAfter(ann, reference.id)
      }
    } else {
      if (reference === null) {
        annotationsMap.addFirst(ann)
      } else {
        annotationsMap.addBefore(ann, reference.id)
      }
    }

    toWorkerInstance?.postMessage(ACTIONS.REORDER_ANNOTATION, {
      toReorder,
      reference,
      direction,
    })

    const orderedList =
      currentFrameAnnotationsFrameDataPerSlot.value[workviewSettings.activeSlotName]
        ?.orderedAnnotationIds
    if (!orderedList) {
      return
    }

    const reorderIndex = orderedList.indexOf(toReorder.id)
    const referenceIndex = reference
      ? orderedList.indexOf(reference.id)
      : direction === 'up'
        ? 0
        : orderedList.length - 1

    if (reorderIndex === -1 || referenceIndex === -1) {
      throw new Error('IDs not found in the array')
    }

    orderedList.splice(reorderIndex, 1)

    const newIndex = referenceIndex

    orderedList.splice(newIndex, 0, toReorder.id)

    triggerRef(annotationsPerSlot)
  }
  const reorderAnnotationOnError = (
    ann: V2AnnotationPayload,
    originalBefore: V2AnnotationPayload | null,
  ): void => {
    reorderAnnotation({ id: ann.id }, originalBefore ? { id: originalBefore.id } : null, 'down')
  }
  const moveAnnotationTop = (annId: string): void => {
    const ann = annotationsMap.get(annId)
    if (!ann) {
      return
    }
    if (
      isAnnotationTag(ann.annotation_class_id) ||
      isAnnotationMask(ann.annotation_class_id) ||
      isRasterLayer(ann.annotation_class_id)
    ) {
      return
    }

    reorderAnnotation({ id: annId }, null, 'up')
  }
  const moveAnnotationBottom = (annId: string): void => {
    const ann = annotationsMap.get(annId)
    if (!ann) {
      return
    }
    if (
      isAnnotationTag(ann.annotation_class_id) ||
      isAnnotationMask(ann.annotation_class_id) ||
      isRasterLayer(ann.annotation_class_id)
    ) {
      return
    }

    reorderAnnotation({ id: annId }, null, 'down')
  }

  const createZIndexForAnnotation = (ann: V2AnnotationPayload): undefined | null | number => {
    if (isAnnotationTag(ann.annotation_class_id)) {
      return null
    }

    // API assign z_index property by itself for non-tag video annotations.
    if ('frames' in ann.data) {
      return undefined
    }

    // assign z_index property for non-tag image annotations.
    // NOTE: keep the old version to be safe, and reduce the API response time.
    return annotationsMap.size.value
  }

  return {
    init,

    parsedFrames,

    getAnnotation,
    getAnnotationRef,

    initAnnotations,
    createAnnotation,
    updateAnnotation,
    removeAnnotation,

    pushMetadata,
    pushFramesData,
    flushFramesData,

    framesNeedsLoading,

    clear,

    reorderAnnotation,
    reorderAnnotationOnError,
    moveAnnotationTop,
    moveAnnotationBottom,

    createZIndexForAnnotation,

    annotationsEntries,
    annotationsPerSlot,
    annotationsMap,
    tagAnnotationsMap: tagsMap,
    allAnnotations,
    allTags,
    allMasks,
    allRasterLayers,
    currentFrameAnnotationsFrameDataPerSlot,

    getAnnotationMainTypeByClassId,
    getAnnotationMainTypeById,
    isAnnotationTag,
  }
})
