// eslint-disable-next-line boundaries/element-types
import type { RenderableImage } from '@/store/modules/workview/types'
import type { WindowLevels } from '@/modules/Editor/imageManipulation'
import {
  resolveRawImageData,
  resolveTransformedImageData,
} from '@/modules/Editor/utils/resolveTransformedImageData'

export type BlendImageData = {
  image: RenderableImage
  windowLevels: WindowLevels
  slotName?: string
}

/**
 * Blendes the passed image urls into a single renderable image, using average blending.
 * It also apply window level changes per file
 */
export const blendImagesWithCanvas = async (
  imageSources: BlendImageData[],
  width: number,
  height: number,
): Promise<RenderableImage | undefined> => {
  const tmpCanvas: HTMLCanvasElement | null = document.createElement('canvas')
  tmpCanvas.width = width
  tmpCanvas.height = height

  const ctx = tmpCanvas.getContext('2d')
  if (!ctx) {
    return
  }
  ctx.imageSmoothingEnabled = false
  imageSources.forEach((source, index) => {
    ctx.globalAlpha = 1 / (index + 1)
    const imageData = resolveRawImageData(source.image.rawData, source.image.data)
    if (!imageData) {
      return
    }

    const transformedImage = resolveTransformedImageData(
      source.image.lastWindowLevels,
      source.windowLevels,
      imageData,
      source.image.data,
    )
    ctx.drawImage(transformedImage || source.image.data, 0, 0)
  })
  ctx.globalAlpha = 1

  const data = await new Promise<HTMLImageElement>((resolve, reject) => {
    const img = new Image()
    img.onload = (): void => resolve(img)
    img.onerror = reject
    img.src = tmpCanvas.toDataURL()
  })

  const rawData = null
  const lastWindowLevels = null
  const lastColorMap = null

  return {
    data,
    rawData,
    transformedData: tmpCanvas,
    lastWindowLevels,
    lastColorMap,
  }
}

/**
 * Given the image data and the window levels passed, it will return a blended renderable image.
 */
export const resolveBlendedRenderableImage = async (
  inputImagesData: BlendImageData[],
): Promise<RenderableImage | undefined> => {
  if (inputImagesData.length === 0) {
    return
  }

  const firstImage = inputImagesData[0].image
  const { width, height } = firstImage.data

  const blendedRenderableImage = await blendImagesWithCanvas(inputImagesData, width, height)

  return blendedRenderableImage
}
